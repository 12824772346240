.split {
    flex: 1;
}
.flex-0 {
    flex: 0;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-0-auto {
    flex: 0 0 auto;
}
.flex-wrap {
    flex-wrap: wrap;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.sticky {
    position: sticky;
}
.fixed {
    position: fixed;
}
.object-cover {
    object-fit: cover;
}
.object-contain {
    object-fit: contain;
}
.z-0 {
    z-index: 0;
}
.-z-1 {
    z-index: -1;
}
.z-1 {
    z-index: 1;
}
.z-100 {
    z-index: 100;
}
.inset-0 {
    inset: 0;
}
.inset-1 {
    inset: 1px;
}
.left-0 {
    left: 0;
}
.left-1\/2 {
    left: 50%;
}
.top-1\/2 {
    top: 50%;
}
.touch-none {
    touch-action: none;
}
.-translate-y-1\/2 {
    transform: translateY(-50%);
}
.-translate-y-full {
    transform: translateY(-100%);
}
.-translate-x-1\/2 {
    transform: translateX(-50%);
}
.right-0 {
    right: 0;
}
.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.capitalize {
    text-transform: capitalize;
}
.-bottom-full {
    bottom: -100%;
}
.opacity-0 {
    opacity: 0;
}
.opacity-10 {
    opacity: 0.1;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-100 {
    opacity: 1;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-auto {
    overflow: auto;
}
.overflow-visible {
    overflow: visible;
}
.block {
    display: block;
}
.flex {
    display: flex;
}
.row {
    display: flex;
    align-items: center;
}
.hidden {
    display: none;
}
@media screen and (min-width: 800px) {
    .md\:block {
        display: block;
    }
}
@media screen and (min-width: 800px) {
    .md\:flex {
        display: flex;
    }
}
@media screen and (min-width: 1100px) {
    .lg\:hidden {
        display: none;
    }
}
@media screen and (min-width: 1100px) {
    .lg\:block {
        display: block;
    }
}
@media screen and (min-width: 1100px) {
    .lg\:flex {
        display: flex;
    }
}
@media screen and (min-width: 800px) {
    .md\:row {
        display: flex;
        align-items: center;
    }
}
@media screen and (min-width: 800px) {
    .md\:hidden {
        display: none;
    }
}
.border {
    border: 1px solid transparent;
}
.border-r {
    border-right: 1px solid transparent;
}
.border-l {
    border-left: 1px solid transparent;
}
.border-t {
    border-top: 1px solid transparent;
}
.border-b {
    border-bottom: 1px solid transparent;
}
.border-2 {
    border: 2px solid transparent;
}
.border-2-r {
    border-right: 2px solid transparent;
}
.border-2-l {
    border-left: 2px solid transparent;
}
.border-2-t {
    border-top: 2px solid transparent;
}
.border-2-b {
    border-bottom: 2px solid transparent;
}
.border-none {
    border: none;
}
.border-r-none {
    border-right: none;
}
.border-t-none {
    border-top: none;
}
.border-l-none {
    border-left: none;
}
.border-b-none {
    border-bottom: none;
}
.stack {
    display: flex;
    flex-direction: column;
}
.justify-end {
    justify-content: flex-end;
}
.justify-start {
    justify-content: flex-start;
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.italic {
    font-style: italic;
}
.rounded-sm {
    border-radius: 2px;
}
.rounded {
    border-radius: 4px;
}
.rounded-lg {
    border-radius: 8px;
}
.rounded-xl {
    border-radius: 12px;
}
.rounded-full {
    border-radius: 100%;
}
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-text {
    cursor: text;
}
.elevation-1 {
    box-shadow: var(--elevation-1);
}
.elevation-5 {
    box-shadow: var(--elevation-5);
}
.elevation-10 {
    box-shadow: var(--elevation-10);
}
.super {
    vertical-align: super;
}
.tabular-nums {
    font-variant: tabular-nums;
}
.list-disc {
    list-style-type: disc;
}
.underline {
    text-decoration: underline;
}
.dashed-underline {
    text-decoration: underline;
    text-decoration-style: dashed;
}
.word-break-all {
    word-break: break-all;
}
.select-none {
    user-select: none;
}
.rotate-90 {
    transform: rotate(90deg);
}
.rotate-180 {
    transform: rotate(180deg);
}
.rotate-270 {
    transform: rotate(270deg);
}
.pre-wrap {
    white-space: pre-wrap;
}
.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.bg-transparent {
    background: transparent;
}
.text-grey-0 {
    color: var(--grey-0);
}
.bg-grey-0 {
    background: var(--grey-0);
}
.fill-grey-0 {
    fill: var(--grey-0);
}
.hover\:bg-grey-0:hover {
    background: var(--grey-0);
}
.border-grey-0 {
    border-color: var(--grey-0);
}
.hover\:border-grey-0:hover {
    border-color: var(--grey-0);
}
.text-grey-5 {
    color: var(--grey-5);
}
.bg-grey-5 {
    background: var(--grey-5);
}
.fill-grey-5 {
    fill: var(--grey-5);
}
.hover\:bg-grey-5:hover {
    background: var(--grey-5);
}
.border-grey-5 {
    border-color: var(--grey-5);
}
.hover\:border-grey-5:hover {
    border-color: var(--grey-5);
}
.text-grey-50 {
    color: var(--grey-50);
}
.bg-grey-50 {
    background: var(--grey-50);
}
.fill-grey-50 {
    fill: var(--grey-50);
}
.hover\:bg-grey-50:hover {
    background: var(--grey-50);
}
.border-grey-50 {
    border-color: var(--grey-50);
}
.hover\:border-grey-50:hover {
    border-color: var(--grey-50);
}
.text-grey-200 {
    color: var(--grey-200);
}
.bg-grey-200 {
    background: var(--grey-200);
}
.fill-grey-200 {
    fill: var(--grey-200);
}
.hover\:bg-grey-200:hover {
    background: var(--grey-200);
}
.border-grey-200 {
    border-color: var(--grey-200);
}
.hover\:border-grey-200:hover {
    border-color: var(--grey-200);
}
.text-grey-400 {
    color: var(--grey-400);
}
.bg-grey-400 {
    background: var(--grey-400);
}
.fill-grey-400 {
    fill: var(--grey-400);
}
.hover\:bg-grey-400:hover {
    background: var(--grey-400);
}
.border-grey-400 {
    border-color: var(--grey-400);
}
.hover\:border-grey-400:hover {
    border-color: var(--grey-400);
}
.text-grey-800 {
    color: var(--grey-800);
}
.bg-grey-800 {
    background: var(--grey-800);
}
.fill-grey-800 {
    fill: var(--grey-800);
}
.hover\:bg-grey-800:hover {
    background: var(--grey-800);
}
.border-grey-800 {
    border-color: var(--grey-800);
}
.hover\:border-grey-800:hover {
    border-color: var(--grey-800);
}
.text-grey-50-a {
    color: var(--grey-50-a);
}
.bg-grey-50-a {
    background: var(--grey-50-a);
}
.fill-grey-50-a {
    fill: var(--grey-50-a);
}
.hover\:bg-grey-50-a:hover {
    background: var(--grey-50-a);
}
.border-grey-50-a {
    border-color: var(--grey-50-a);
}
.hover\:border-grey-50-a:hover {
    border-color: var(--grey-50-a);
}
.text-grey-100-a {
    color: var(--grey-100-a);
}
.bg-grey-100-a {
    background: var(--grey-100-a);
}
.fill-grey-100-a {
    fill: var(--grey-100-a);
}
.hover\:bg-grey-100-a:hover {
    background: var(--grey-100-a);
}
.border-grey-100-a {
    border-color: var(--grey-100-a);
}
.hover\:border-grey-100-a:hover {
    border-color: var(--grey-100-a);
}
.text-grey-400-a {
    color: var(--grey-400-a);
}
.bg-grey-400-a {
    background: var(--grey-400-a);
}
.fill-grey-400-a {
    fill: var(--grey-400-a);
}
.hover\:bg-grey-400-a:hover {
    background: var(--grey-400-a);
}
.border-grey-400-a {
    border-color: var(--grey-400-a);
}
.hover\:border-grey-400-a:hover {
    border-color: var(--grey-400-a);
}
.text-cyan-200 {
    color: var(--cyan-200);
}
.bg-cyan-200 {
    background: var(--cyan-200);
}
.fill-cyan-200 {
    fill: var(--cyan-200);
}
.hover\:bg-cyan-200:hover {
    background: var(--cyan-200);
}
.border-cyan-200 {
    border-color: var(--cyan-200);
}
.hover\:border-cyan-200:hover {
    border-color: var(--cyan-200);
}
.text-cyan-500 {
    color: var(--cyan-500);
}
.bg-cyan-500 {
    background: var(--cyan-500);
}
.fill-cyan-500 {
    fill: var(--cyan-500);
}
.hover\:bg-cyan-500:hover {
    background: var(--cyan-500);
}
.border-cyan-500 {
    border-color: var(--cyan-500);
}
.hover\:border-cyan-500:hover {
    border-color: var(--cyan-500);
}
.text-cyan-800 {
    color: var(--cyan-800);
}
.bg-cyan-800 {
    background: var(--cyan-800);
}
.fill-cyan-800 {
    fill: var(--cyan-800);
}
.hover\:bg-cyan-800:hover {
    background: var(--cyan-800);
}
.border-cyan-800 {
    border-color: var(--cyan-800);
}
.hover\:border-cyan-800:hover {
    border-color: var(--cyan-800);
}
.text-cyan-500-a {
    color: var(--cyan-500-a);
}
.bg-cyan-500-a {
    background: var(--cyan-500-a);
}
.fill-cyan-500-a {
    fill: var(--cyan-500-a);
}
.hover\:bg-cyan-500-a:hover {
    background: var(--cyan-500-a);
}
.border-cyan-500-a {
    border-color: var(--cyan-500-a);
}
.hover\:border-cyan-500-a:hover {
    border-color: var(--cyan-500-a);
}
.text-blue-200 {
    color: var(--blue-200);
}
.bg-blue-200 {
    background: var(--blue-200);
}
.fill-blue-200 {
    fill: var(--blue-200);
}
.hover\:bg-blue-200:hover {
    background: var(--blue-200);
}
.border-blue-200 {
    border-color: var(--blue-200);
}
.hover\:border-blue-200:hover {
    border-color: var(--blue-200);
}
.text-blue-800 {
    color: var(--blue-800);
}
.bg-blue-800 {
    background: var(--blue-800);
}
.fill-blue-800 {
    fill: var(--blue-800);
}
.hover\:bg-blue-800:hover {
    background: var(--blue-800);
}
.border-blue-800 {
    border-color: var(--blue-800);
}
.hover\:border-blue-800:hover {
    border-color: var(--blue-800);
}
.text-blue-500 {
    color: var(--blue-500);
}
.bg-blue-500 {
    background: var(--blue-500);
}
.fill-blue-500 {
    fill: var(--blue-500);
}
.hover\:bg-blue-500:hover {
    background: var(--blue-500);
}
.border-blue-500 {
    border-color: var(--blue-500);
}
.hover\:border-blue-500:hover {
    border-color: var(--blue-500);
}
.text-blue-500-a {
    color: var(--blue-500-a);
}
.bg-blue-500-a {
    background: var(--blue-500-a);
}
.fill-blue-500-a {
    fill: var(--blue-500-a);
}
.hover\:bg-blue-500-a:hover {
    background: var(--blue-500-a);
}
.border-blue-500-a {
    border-color: var(--blue-500-a);
}
.hover\:border-blue-500-a:hover {
    border-color: var(--blue-500-a);
}
.text-purple-200 {
    color: var(--purple-200);
}
.bg-purple-200 {
    background: var(--purple-200);
}
.fill-purple-200 {
    fill: var(--purple-200);
}
.hover\:bg-purple-200:hover {
    background: var(--purple-200);
}
.border-purple-200 {
    border-color: var(--purple-200);
}
.hover\:border-purple-200:hover {
    border-color: var(--purple-200);
}
.text-purple-500 {
    color: var(--purple-500);
}
.bg-purple-500 {
    background: var(--purple-500);
}
.fill-purple-500 {
    fill: var(--purple-500);
}
.hover\:bg-purple-500:hover {
    background: var(--purple-500);
}
.border-purple-500 {
    border-color: var(--purple-500);
}
.hover\:border-purple-500:hover {
    border-color: var(--purple-500);
}
.text-purple-800 {
    color: var(--purple-800);
}
.bg-purple-800 {
    background: var(--purple-800);
}
.fill-purple-800 {
    fill: var(--purple-800);
}
.hover\:bg-purple-800:hover {
    background: var(--purple-800);
}
.border-purple-800 {
    border-color: var(--purple-800);
}
.hover\:border-purple-800:hover {
    border-color: var(--purple-800);
}
.text-purple-500-a {
    color: var(--purple-500-a);
}
.bg-purple-500-a {
    background: var(--purple-500-a);
}
.fill-purple-500-a {
    fill: var(--purple-500-a);
}
.hover\:bg-purple-500-a:hover {
    background: var(--purple-500-a);
}
.border-purple-500-a {
    border-color: var(--purple-500-a);
}
.hover\:border-purple-500-a:hover {
    border-color: var(--purple-500-a);
}
.text-pink-200 {
    color: var(--pink-200);
}
.bg-pink-200 {
    background: var(--pink-200);
}
.fill-pink-200 {
    fill: var(--pink-200);
}
.hover\:bg-pink-200:hover {
    background: var(--pink-200);
}
.border-pink-200 {
    border-color: var(--pink-200);
}
.hover\:border-pink-200:hover {
    border-color: var(--pink-200);
}
.text-pink-500 {
    color: var(--pink-500);
}
.bg-pink-500 {
    background: var(--pink-500);
}
.fill-pink-500 {
    fill: var(--pink-500);
}
.hover\:bg-pink-500:hover {
    background: var(--pink-500);
}
.border-pink-500 {
    border-color: var(--pink-500);
}
.hover\:border-pink-500:hover {
    border-color: var(--pink-500);
}
.text-pink-800 {
    color: var(--pink-800);
}
.bg-pink-800 {
    background: var(--pink-800);
}
.fill-pink-800 {
    fill: var(--pink-800);
}
.hover\:bg-pink-800:hover {
    background: var(--pink-800);
}
.border-pink-800 {
    border-color: var(--pink-800);
}
.hover\:border-pink-800:hover {
    border-color: var(--pink-800);
}
.text-pink-500-a {
    color: var(--pink-500-a);
}
.bg-pink-500-a {
    background: var(--pink-500-a);
}
.fill-pink-500-a {
    fill: var(--pink-500-a);
}
.hover\:bg-pink-500-a:hover {
    background: var(--pink-500-a);
}
.border-pink-500-a {
    border-color: var(--pink-500-a);
}
.hover\:border-pink-500-a:hover {
    border-color: var(--pink-500-a);
}
.text-red-200 {
    color: var(--red-200);
}
.bg-red-200 {
    background: var(--red-200);
}
.fill-red-200 {
    fill: var(--red-200);
}
.hover\:bg-red-200:hover {
    background: var(--red-200);
}
.border-red-200 {
    border-color: var(--red-200);
}
.hover\:border-red-200:hover {
    border-color: var(--red-200);
}
.text-red-500 {
    color: var(--red-500);
}
.bg-red-500 {
    background: var(--red-500);
}
.fill-red-500 {
    fill: var(--red-500);
}
.hover\:bg-red-500:hover {
    background: var(--red-500);
}
.border-red-500 {
    border-color: var(--red-500);
}
.hover\:border-red-500:hover {
    border-color: var(--red-500);
}
.text-red-800 {
    color: var(--red-800);
}
.bg-red-800 {
    background: var(--red-800);
}
.fill-red-800 {
    fill: var(--red-800);
}
.hover\:bg-red-800:hover {
    background: var(--red-800);
}
.border-red-800 {
    border-color: var(--red-800);
}
.hover\:border-red-800:hover {
    border-color: var(--red-800);
}
.text-red-500-a {
    color: var(--red-500-a);
}
.bg-red-500-a {
    background: var(--red-500-a);
}
.fill-red-500-a {
    fill: var(--red-500-a);
}
.hover\:bg-red-500-a:hover {
    background: var(--red-500-a);
}
.border-red-500-a {
    border-color: var(--red-500-a);
}
.hover\:border-red-500-a:hover {
    border-color: var(--red-500-a);
}
.text-orange-200 {
    color: var(--orange-200);
}
.bg-orange-200 {
    background: var(--orange-200);
}
.fill-orange-200 {
    fill: var(--orange-200);
}
.hover\:bg-orange-200:hover {
    background: var(--orange-200);
}
.border-orange-200 {
    border-color: var(--orange-200);
}
.hover\:border-orange-200:hover {
    border-color: var(--orange-200);
}
.text-orange-500 {
    color: var(--orange-500);
}
.bg-orange-500 {
    background: var(--orange-500);
}
.fill-orange-500 {
    fill: var(--orange-500);
}
.hover\:bg-orange-500:hover {
    background: var(--orange-500);
}
.border-orange-500 {
    border-color: var(--orange-500);
}
.hover\:border-orange-500:hover {
    border-color: var(--orange-500);
}
.text-orange-800 {
    color: var(--orange-800);
}
.bg-orange-800 {
    background: var(--orange-800);
}
.fill-orange-800 {
    fill: var(--orange-800);
}
.hover\:bg-orange-800:hover {
    background: var(--orange-800);
}
.border-orange-800 {
    border-color: var(--orange-800);
}
.hover\:border-orange-800:hover {
    border-color: var(--orange-800);
}
.text-orange-500-a {
    color: var(--orange-500-a);
}
.bg-orange-500-a {
    background: var(--orange-500-a);
}
.fill-orange-500-a {
    fill: var(--orange-500-a);
}
.hover\:bg-orange-500-a:hover {
    background: var(--orange-500-a);
}
.border-orange-500-a {
    border-color: var(--orange-500-a);
}
.hover\:border-orange-500-a:hover {
    border-color: var(--orange-500-a);
}
.text-amber-200 {
    color: var(--amber-200);
}
.bg-amber-200 {
    background: var(--amber-200);
}
.fill-amber-200 {
    fill: var(--amber-200);
}
.hover\:bg-amber-200:hover {
    background: var(--amber-200);
}
.border-amber-200 {
    border-color: var(--amber-200);
}
.hover\:border-amber-200:hover {
    border-color: var(--amber-200);
}
.text-amber-500 {
    color: var(--amber-500);
}
.bg-amber-500 {
    background: var(--amber-500);
}
.fill-amber-500 {
    fill: var(--amber-500);
}
.hover\:bg-amber-500:hover {
    background: var(--amber-500);
}
.border-amber-500 {
    border-color: var(--amber-500);
}
.hover\:border-amber-500:hover {
    border-color: var(--amber-500);
}
.text-amber-800 {
    color: var(--amber-800);
}
.bg-amber-800 {
    background: var(--amber-800);
}
.fill-amber-800 {
    fill: var(--amber-800);
}
.hover\:bg-amber-800:hover {
    background: var(--amber-800);
}
.border-amber-800 {
    border-color: var(--amber-800);
}
.hover\:border-amber-800:hover {
    border-color: var(--amber-800);
}
.text-amber-500-a {
    color: var(--amber-500-a);
}
.bg-amber-500-a {
    background: var(--amber-500-a);
}
.fill-amber-500-a {
    fill: var(--amber-500-a);
}
.hover\:bg-amber-500-a:hover {
    background: var(--amber-500-a);
}
.border-amber-500-a {
    border-color: var(--amber-500-a);
}
.hover\:border-amber-500-a:hover {
    border-color: var(--amber-500-a);
}
.text-yellow-200 {
    color: var(--yellow-200);
}
.bg-yellow-200 {
    background: var(--yellow-200);
}
.fill-yellow-200 {
    fill: var(--yellow-200);
}
.hover\:bg-yellow-200:hover {
    background: var(--yellow-200);
}
.border-yellow-200 {
    border-color: var(--yellow-200);
}
.hover\:border-yellow-200:hover {
    border-color: var(--yellow-200);
}
.text-yellow-500 {
    color: var(--yellow-500);
}
.bg-yellow-500 {
    background: var(--yellow-500);
}
.fill-yellow-500 {
    fill: var(--yellow-500);
}
.hover\:bg-yellow-500:hover {
    background: var(--yellow-500);
}
.border-yellow-500 {
    border-color: var(--yellow-500);
}
.hover\:border-yellow-500:hover {
    border-color: var(--yellow-500);
}
.text-yellow-800 {
    color: var(--yellow-800);
}
.bg-yellow-800 {
    background: var(--yellow-800);
}
.fill-yellow-800 {
    fill: var(--yellow-800);
}
.hover\:bg-yellow-800:hover {
    background: var(--yellow-800);
}
.border-yellow-800 {
    border-color: var(--yellow-800);
}
.hover\:border-yellow-800:hover {
    border-color: var(--yellow-800);
}
.text-yellow-500-a {
    color: var(--yellow-500-a);
}
.bg-yellow-500-a {
    background: var(--yellow-500-a);
}
.fill-yellow-500-a {
    fill: var(--yellow-500-a);
}
.hover\:bg-yellow-500-a:hover {
    background: var(--yellow-500-a);
}
.border-yellow-500-a {
    border-color: var(--yellow-500-a);
}
.hover\:border-yellow-500-a:hover {
    border-color: var(--yellow-500-a);
}
.text-green-200 {
    color: var(--green-200);
}
.bg-green-200 {
    background: var(--green-200);
}
.fill-green-200 {
    fill: var(--green-200);
}
.hover\:bg-green-200:hover {
    background: var(--green-200);
}
.border-green-200 {
    border-color: var(--green-200);
}
.hover\:border-green-200:hover {
    border-color: var(--green-200);
}
.text-green-500 {
    color: var(--green-500);
}
.bg-green-500 {
    background: var(--green-500);
}
.fill-green-500 {
    fill: var(--green-500);
}
.hover\:bg-green-500:hover {
    background: var(--green-500);
}
.border-green-500 {
    border-color: var(--green-500);
}
.hover\:border-green-500:hover {
    border-color: var(--green-500);
}
.text-green-800 {
    color: var(--green-800);
}
.bg-green-800 {
    background: var(--green-800);
}
.fill-green-800 {
    fill: var(--green-800);
}
.hover\:bg-green-800:hover {
    background: var(--green-800);
}
.border-green-800 {
    border-color: var(--green-800);
}
.hover\:border-green-800:hover {
    border-color: var(--green-800);
}
.text-green-500-a {
    color: var(--green-500-a);
}
.bg-green-500-a {
    background: var(--green-500-a);
}
.fill-green-500-a {
    fill: var(--green-500-a);
}
.hover\:bg-green-500-a:hover {
    background: var(--green-500-a);
}
.border-green-500-a {
    border-color: var(--green-500-a);
}
.hover\:border-green-500-a:hover {
    border-color: var(--green-500-a);
}
.text-brown-200 {
    color: var(--brown-200);
}
.bg-brown-200 {
    background: var(--brown-200);
}
.fill-brown-200 {
    fill: var(--brown-200);
}
.hover\:bg-brown-200:hover {
    background: var(--brown-200);
}
.border-brown-200 {
    border-color: var(--brown-200);
}
.hover\:border-brown-200:hover {
    border-color: var(--brown-200);
}
.text-brown-800 {
    color: var(--brown-800);
}
.bg-brown-800 {
    background: var(--brown-800);
}
.fill-brown-800 {
    fill: var(--brown-800);
}
.hover\:bg-brown-800:hover {
    background: var(--brown-800);
}
.border-brown-800 {
    border-color: var(--brown-800);
}
.hover\:border-brown-800:hover {
    border-color: var(--brown-800);
}
.text-chrome-0-recessed {
    color: var(--chrome-0-recessed);
}
.bg-chrome-0-recessed {
    background: var(--chrome-0-recessed);
}
.fill-chrome-0-recessed {
    fill: var(--chrome-0-recessed);
}
.hover\:bg-chrome-0-recessed:hover {
    background: var(--chrome-0-recessed);
}
.border-chrome-0-recessed {
    border-color: var(--chrome-0-recessed);
}
.hover\:border-chrome-0-recessed:hover {
    border-color: var(--chrome-0-recessed);
}
.text-chrome-0-elevated {
    color: var(--chrome-0-elevated);
}
.bg-chrome-0-elevated {
    background: var(--chrome-0-elevated);
}
.fill-chrome-0-elevated {
    fill: var(--chrome-0-elevated);
}
.hover\:bg-chrome-0-elevated:hover {
    background: var(--chrome-0-elevated);
}
.border-chrome-0-elevated {
    border-color: var(--chrome-0-elevated);
}
.hover\:border-chrome-0-elevated:hover {
    border-color: var(--chrome-0-elevated);
}
.text-grey-100-a-inverse {
    color: var(--grey-100-a-inverse);
}
.bg-grey-100-a-inverse {
    background: var(--grey-100-a-inverse);
}
.fill-grey-100-a-inverse {
    fill: var(--grey-100-a-inverse);
}
.hover\:bg-grey-100-a-inverse:hover {
    background: var(--grey-100-a-inverse);
}
.border-grey-100-a-inverse {
    border-color: var(--grey-100-a-inverse);
}
.hover\:border-grey-100-a-inverse:hover {
    border-color: var(--grey-100-a-inverse);
}
.text-drop-shadow-soft {
    color: var(--drop-shadow-soft);
}
.bg-drop-shadow-soft {
    background: var(--drop-shadow-soft);
}
.fill-drop-shadow-soft {
    fill: var(--drop-shadow-soft);
}
.hover\:bg-drop-shadow-soft:hover {
    background: var(--drop-shadow-soft);
}
.border-drop-shadow-soft {
    border-color: var(--drop-shadow-soft);
}
.hover\:border-drop-shadow-soft:hover {
    border-color: var(--drop-shadow-soft);
}
.text-drop-shadow-medium {
    color: var(--drop-shadow-medium);
}
.bg-drop-shadow-medium {
    background: var(--drop-shadow-medium);
}
.fill-drop-shadow-medium {
    fill: var(--drop-shadow-medium);
}
.hover\:bg-drop-shadow-medium:hover {
    background: var(--drop-shadow-medium);
}
.border-drop-shadow-medium {
    border-color: var(--drop-shadow-medium);
}
.hover\:border-drop-shadow-medium:hover {
    border-color: var(--drop-shadow-medium);
}
.text-drop-shadow-hard {
    color: var(--drop-shadow-hard);
}
.bg-drop-shadow-hard {
    background: var(--drop-shadow-hard);
}
.fill-drop-shadow-hard {
    fill: var(--drop-shadow-hard);
}
.hover\:bg-drop-shadow-hard:hover {
    background: var(--drop-shadow-hard);
}
.border-drop-shadow-hard {
    border-color: var(--drop-shadow-hard);
}
.hover\:border-drop-shadow-hard:hover {
    border-color: var(--drop-shadow-hard);
}
.text-upgrade-primary {
    color: var(--upgrade-primary);
}
.bg-upgrade-primary {
    background: var(--upgrade-primary);
}
.fill-upgrade-primary {
    fill: var(--upgrade-primary);
}
.hover\:bg-upgrade-primary:hover {
    background: var(--upgrade-primary);
}
.border-upgrade-primary {
    border-color: var(--upgrade-primary);
}
.hover\:border-upgrade-primary:hover {
    border-color: var(--upgrade-primary);
}
.text-upgrade-background {
    color: var(--upgrade-background);
}
.bg-upgrade-background {
    background: var(--upgrade-background);
}
.fill-upgrade-background {
    fill: var(--upgrade-background);
}
.hover\:bg-upgrade-background:hover {
    background: var(--upgrade-background);
}
.border-upgrade-background {
    border-color: var(--upgrade-background);
}
.hover\:border-upgrade-background:hover {
    border-color: var(--upgrade-background);
}
.text-upgrade-button-background {
    color: var(--upgrade-button-background);
}
.bg-upgrade-button-background {
    background: var(--upgrade-button-background);
}
.fill-upgrade-button-background {
    fill: var(--upgrade-button-background);
}
.hover\:bg-upgrade-button-background:hover {
    background: var(--upgrade-button-background);
}
.border-upgrade-button-background {
    border-color: var(--upgrade-button-background);
}
.hover\:border-upgrade-button-background:hover {
    border-color: var(--upgrade-button-background);
}
.text-drop-shadow-none {
    color: var(--drop-shadow-none);
}
.bg-drop-shadow-none {
    background: var(--drop-shadow-none);
}
.fill-drop-shadow-none {
    fill: var(--drop-shadow-none);
}
.hover\:bg-drop-shadow-none:hover {
    background: var(--drop-shadow-none);
}
.border-drop-shadow-none {
    border-color: var(--drop-shadow-none);
}
.hover\:border-drop-shadow-none:hover {
    border-color: var(--drop-shadow-none);
}
.text-waveform-default {
    color: var(--waveform-default);
}
.bg-waveform-default {
    background: var(--waveform-default);
}
.fill-waveform-default {
    fill: var(--waveform-default);
}
.hover\:bg-waveform-default:hover {
    background: var(--waveform-default);
}
.border-waveform-default {
    border-color: var(--waveform-default);
}
.hover\:border-waveform-default:hover {
    border-color: var(--waveform-default);
}
.text-waveform-selected {
    color: var(--waveform-selected);
}
.bg-waveform-selected {
    background: var(--waveform-selected);
}
.fill-waveform-selected {
    fill: var(--waveform-selected);
}
.hover\:bg-waveform-selected:hover {
    background: var(--waveform-selected);
}
.border-waveform-selected {
    border-color: var(--waveform-selected);
}
.hover\:border-waveform-selected:hover {
    border-color: var(--waveform-selected);
}
.text-waveform {
    color: var(--waveform);
}
.bg-waveform {
    background: var(--waveform);
}
.fill-waveform {
    fill: var(--waveform);
}
.hover\:bg-waveform:hover {
    background: var(--waveform);
}
.border-waveform {
    border-color: var(--waveform);
}
.hover\:border-waveform:hover {
    border-color: var(--waveform);
}
.text-cyan-600 {
    color: var(--cyan-600);
}
.bg-cyan-600 {
    background: var(--cyan-600);
}
.fill-cyan-600 {
    fill: var(--cyan-600);
}
.hover\:bg-cyan-600:hover {
    background: var(--cyan-600);
}
.border-cyan-600 {
    border-color: var(--cyan-600);
}
.hover\:border-cyan-600:hover {
    border-color: var(--cyan-600);
}
.text-grey-30-a {
    color: var(--grey-30-a);
}
.bg-grey-30-a {
    background: var(--grey-30-a);
}
.fill-grey-30-a {
    fill: var(--grey-30-a);
}
.hover\:bg-grey-30-a:hover {
    background: var(--grey-30-a);
}
.border-grey-30-a {
    border-color: var(--grey-30-a);
}
.hover\:border-grey-30-a:hover {
    border-color: var(--grey-30-a);
}
.state-inactive {
    color: var(--state-inactive);
}
.text-static-black {
    color: var(--static-black);
}
.bg-static-black {
    background: var(--static-black);
}
.fill-static-black {
    fill: var(--static-black);
}
.hover\:bg-static-black:hover {
    background: var(--static-black);
}
.border-static-black {
    border-color: var(--static-black);
}
.hover\:border-static-black:hover {
    border-color: var(--static-black);
}
.text-static-white {
    color: var(--static-white);
}
.bg-static-white {
    background: var(--static-white);
}
.fill-static-white {
    fill: var(--static-white);
}
.hover\:bg-static-white:hover {
    background: var(--static-white);
}
.border-static-white {
    border-color: var(--static-white);
}
.hover\:border-static-white:hover {
    border-color: var(--static-white);
}
.label-1-medium {
    font-family: var(--label-1-medium-font-family);
    font-size: var(--label-1-medium-font-size);
    font-weight: var(--label-1-medium-font-weight);
    letter-spacing: var(--label-1-medium-letter-spacing);
    line-height: var(--label-1-medium-line-height);
}
.label-1-regular {
    font-family: var(--label-1-regular-font-family);
    font-size: var(--label-1-regular-font-size);
    font-weight: var(--label-1-regular-font-weight);
    letter-spacing: var(--label-1-regular-letter-spacing);
    line-height: var(--label-1-regular-line-height);
}
.label-2-medium {
    font-family: var(--label-2-medium-font-family);
    font-size: var(--label-2-medium-font-size);
    font-weight: var(--label-2-medium-font-weight);
    letter-spacing: var(--label-2-medium-letter-spacing);
    line-height: var(--label-2-medium-line-height);
}
.body-2-script-regular {
    font-family: var(--body-2-script-regular-font-family);
    font-size: var(--body-2-script-regular-font-size);
    font-weight: var(--body-2-script-regular-font-weight);
    letter-spacing: var(--body-2-script-regular-letter-spacing);
    line-height: var(--body-2-script-regular-line-height);
}
.body-1-script-regular {
    font-family: var(--body-1-script-regular-font-family);
    font-size: var(--body-1-script-regular-font-size);
    font-weight: var(--body-1-script-regular-font-weight);
    letter-spacing: var(--body-1-script-regular-letter-spacing);
    line-height: var(--body-1-script-regular-line-height);
}
.headline-4 {
    font-family: var(--headline-4-font-family);
    font-size: var(--headline-4-font-size);
    font-weight: var(--headline-4-font-weight);
    letter-spacing: var(--headline-4-letter-spacing);
    line-height: var(--headline-4-line-height);
}
.headline-3 {
    font-family: var(--headline-3-font-family);
    font-size: var(--headline-3-font-size);
    font-weight: var(--headline-3-font-weight);
    letter-spacing: var(--headline-3-letter-spacing);
    line-height: var(--headline-3-line-height);
}
.headline-2 {
    font-family: var(--headline-2-font-family);
    font-size: var(--headline-2-font-size);
    font-weight: var(--headline-2-font-weight);
    letter-spacing: var(--headline-2-letter-spacing);
    line-height: var(--headline-2-line-height);
}
.headline-1 {
    font-family: var(--headline-1-font-family);
    font-size: var(--headline-1-font-size);
    font-weight: var(--headline-1-font-weight);
    letter-spacing: var(--headline-1-letter-spacing);
    line-height: var(--headline-1-line-height);
}
.label-2-regular {
    font-family: var(--label-2-regular-font-family);
    font-size: var(--label-2-regular-font-size);
    font-weight: var(--label-2-regular-font-weight);
    letter-spacing: var(--label-2-regular-letter-spacing);
    line-height: var(--label-2-regular-line-height);
}
.badge {
    font-family: var(--badge-font-family);
    font-size: var(--badge-font-size);
    font-weight: var(--badge-font-weight);
    letter-spacing: var(--badge-letter-spacing);
    line-height: var(--badge-line-height);
}
.body-1-script-medium {
    font-family: var(--body-1-script-medium-font-family);
    font-size: var(--body-1-script-medium-font-size);
    font-weight: var(--body-1-script-medium-font-weight);
    letter-spacing: var(--body-1-script-medium-letter-spacing);
    line-height: var(--body-1-script-medium-line-height);
}
.body-2-script-medium {
    font-family: var(--body-2-script-medium-font-family);
    font-size: var(--body-2-script-medium-font-size);
    font-weight: var(--body-2-script-medium-font-weight);
    letter-spacing: var(--body-2-script-medium-letter-spacing);
    line-height: var(--body-2-script-medium-line-height);
}
.body-1-regular {
    font-family: var(--body-1-regular-font-family);
    font-size: var(--body-1-regular-font-size);
    font-weight: var(--body-1-regular-font-weight);
    letter-spacing: var(--body-1-regular-letter-spacing);
    line-height: var(--body-1-regular-line-height);
}
.body-1-medium {
    font-family: var(--body-1-medium-font-family);
    font-size: var(--body-1-medium-font-size);
    font-weight: var(--body-1-medium-font-weight);
    letter-spacing: var(--body-1-medium-letter-spacing);
    line-height: var(--body-1-medium-line-height);
}
.body-2-regular {
    font-family: var(--body-2-regular-font-family);
    font-size: var(--body-2-regular-font-size);
    font-weight: var(--body-2-regular-font-weight);
    letter-spacing: var(--body-2-regular-letter-spacing);
    line-height: var(--body-2-regular-line-height);
}
.body-2-medium {
    font-family: var(--body-2-medium-font-family);
    font-size: var(--body-2-medium-font-size);
    font-weight: var(--body-2-medium-font-weight);
    letter-spacing: var(--body-2-medium-letter-spacing);
    line-height: var(--body-2-medium-line-height);
}
.gap-0 {
    gap: 0;
}
.gap-1 {
    gap: 4px;
}
.gap-2 {
    gap: 8px;
}
.gap-3 {
    gap: 16px;
}
.gap-4 {
    gap: 24px;
}
.gap-5 {
    gap: 32px;
}
.gap-6 {
    gap: 40px;
}
.gap-7 {
    gap: 48px;
}
.gap-8 {
    gap: 56px;
}
.gap-0\.5 {
    gap: 2px;
}
.p-0 {
    padding: 0;
}
.pl-0 {
    padding-left: 0;
}
.pr-0 {
    padding-right: 0;
}
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.pt-0 {
    padding-top: 0;
}
.pb-0 {
    padding-bottom: 0;
}
.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.p-1 {
    padding: 4px;
}
.pl-1 {
    padding-left: 4px;
}
.pr-1 {
    padding-right: 4px;
}
.px-1 {
    padding-left: 4px;
    padding-right: 4px;
}
.pt-1 {
    padding-top: 4px;
}
.pb-1 {
    padding-bottom: 4px;
}
.py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
}
.p-2 {
    padding: 8px;
}
.pl-2 {
    padding-left: 8px;
}
.pr-2 {
    padding-right: 8px;
}
.px-2 {
    padding-left: 8px;
    padding-right: 8px;
}
.pt-2 {
    padding-top: 8px;
}
.pb-2 {
    padding-bottom: 8px;
}
.py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
}
.p-3 {
    padding: 16px;
}
.pl-3 {
    padding-left: 16px;
}
.pr-3 {
    padding-right: 16px;
}
.px-3 {
    padding-left: 16px;
    padding-right: 16px;
}
.pt-3 {
    padding-top: 16px;
}
.pb-3 {
    padding-bottom: 16px;
}
.py-3 {
    padding-top: 16px;
    padding-bottom: 16px;
}
.p-4 {
    padding: 24px;
}
.pl-4 {
    padding-left: 24px;
}
.pr-4 {
    padding-right: 24px;
}
.px-4 {
    padding-left: 24px;
    padding-right: 24px;
}
.pt-4 {
    padding-top: 24px;
}
.pb-4 {
    padding-bottom: 24px;
}
.py-4 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.p-5 {
    padding: 32px;
}
.pl-5 {
    padding-left: 32px;
}
.pr-5 {
    padding-right: 32px;
}
.px-5 {
    padding-left: 32px;
    padding-right: 32px;
}
.pt-5 {
    padding-top: 32px;
}
.pb-5 {
    padding-bottom: 32px;
}
.py-5 {
    padding-top: 32px;
    padding-bottom: 32px;
}
.p-6 {
    padding: 40px;
}
.pl-6 {
    padding-left: 40px;
}
.pr-6 {
    padding-right: 40px;
}
.px-6 {
    padding-left: 40px;
    padding-right: 40px;
}
.pt-6 {
    padding-top: 40px;
}
.pb-6 {
    padding-bottom: 40px;
}
.py-6 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.p-7 {
    padding: 48px;
}
.pl-7 {
    padding-left: 48px;
}
.pr-7 {
    padding-right: 48px;
}
.px-7 {
    padding-left: 48px;
    padding-right: 48px;
}
.pt-7 {
    padding-top: 48px;
}
.pb-7 {
    padding-bottom: 48px;
}
.py-7 {
    padding-top: 48px;
    padding-bottom: 48px;
}
.p-8 {
    padding: 56px;
}
.pl-8 {
    padding-left: 56px;
}
.pr-8 {
    padding-right: 56px;
}
.px-8 {
    padding-left: 56px;
    padding-right: 56px;
}
.pt-8 {
    padding-top: 56px;
}
.pb-8 {
    padding-bottom: 56px;
}
.py-8 {
    padding-top: 56px;
    padding-bottom: 56px;
}
.p-0\.5 {
    padding: 2px;
}
.pl-0\.5 {
    padding-left: 2px;
}
.pr-0\.5 {
    padding-right: 2px;
}
.px-0\.5 {
    padding-left: 2px;
    padding-right: 2px;
}
.pt-0\.5 {
    padding-top: 2px;
}
.pb-0\.5 {
    padding-bottom: 2px;
}
.py-0\.5 {
    padding-top: 2px;
    padding-bottom: 2px;
}
.min-h-full {
    min-height: 100%;
}
.min-h-screen {
    min-height: 100vh;
    min-height: 100dvh;
}
.h-screen {
    height: 100vh;
    height: 100dvh;
}
.h-full {
    height: 100%;
}
.h-fit {
    height: fit-content;
}
.h-auto {
    height: auto;
}
.min-w-0 {
    min-width: 0;
}
.h-0 {
    height: 0;
}
.min-h-0 {
    min-height: 0;
}
.h-1 {
    height: 4px;
}
.min-h-1 {
    min-height: 4px;
}
.h-2 {
    height: 8px;
}
.min-h-2 {
    min-height: 8px;
}
.h-3 {
    height: 16px;
}
.min-h-3 {
    min-height: 16px;
}
.h-4 {
    height: 24px;
}
.min-h-4 {
    min-height: 24px;
}
.h-5 {
    height: 32px;
}
.min-h-5 {
    min-height: 32px;
}
.h-6 {
    height: 40px;
}
.min-h-6 {
    min-height: 40px;
}
.h-7 {
    height: 48px;
}
.min-h-7 {
    min-height: 48px;
}
.h-8 {
    height: 56px;
}
.min-h-8 {
    min-height: 56px;
}
.h-0\.5 {
    height: 2px;
}
.min-h-0\.5 {
    min-height: 2px;
}
.w-auto {
    width: auto;
}
.w-full {
    width: 100%;
}
.w-fit {
    width: fit-content;
}
.w-0 {
    width: 0;
}
.w-1 {
    width: 4px;
}
.w-2 {
    width: 8px;
}
.w-3 {
    width: 16px;
}
.w-4 {
    width: 24px;
}
.w-5 {
    width: 32px;
}
.w-6 {
    width: 40px;
}
.w-7 {
    width: 48px;
}
.w-8 {
    width: 56px;
}
.w-0\.5 {
    width: 2px;
}
.w-xs {
    width: 320px;
}
.w-sm {
    width: 368px;
}
.w-md {
    width: 424px;
}
.w-lg {
    width: 504px;
}
.w-xl {
    width: 600px;
}
.w-2xl {
    width: 720px;
}
.w-max {
    width: max-content;
}
.max-w-fit {
    max-width: fit-content;
}
.max-w-xs {
    max-width: 320px;
}
.max-w-sm {
    max-width: 368px;
}
.max-w-md {
    max-width: 424px;
}
.max-w-lg {
    max-width: 504px;
}
.max-w-xl {
    max-width: 600px;
}
.max-w-2xl {
    max-width: 720px;
}
.min-w-0 {
    min-width: 0;
}
.m-auto {
    margin: auto;
}
.ml-auto {
    margin-left: auto;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.-mx-2 {
    margin-left: -8px;
    margin-right: -8px;
}
.m-0 {
    margin: 0;
}
.ml-0 {
    margin-left: 0;
}
.mr-0 {
    margin-right: 0;
}
.mx-0 {
    margin-left: 0;
    margin-right: 0;
}
.mt-0 {
    margin-top: 0;
}
.mb-0 {
    margin-bottom: 0;
}
.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}
.m-1 {
    margin: 4px;
}
.ml-1 {
    margin-left: 4px;
}
.mr-1 {
    margin-right: 4px;
}
.mx-1 {
    margin-left: 4px;
    margin-right: 4px;
}
.mt-1 {
    margin-top: 4px;
}
.mb-1 {
    margin-bottom: 4px;
}
.my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
}
.m-2 {
    margin: 8px;
}
.ml-2 {
    margin-left: 8px;
}
.mr-2 {
    margin-right: 8px;
}
.mx-2 {
    margin-left: 8px;
    margin-right: 8px;
}
.mt-2 {
    margin-top: 8px;
}
.mb-2 {
    margin-bottom: 8px;
}
.my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
}
.m-3 {
    margin: 16px;
}
.ml-3 {
    margin-left: 16px;
}
.mr-3 {
    margin-right: 16px;
}
.mx-3 {
    margin-left: 16px;
    margin-right: 16px;
}
.mt-3 {
    margin-top: 16px;
}
.mb-3 {
    margin-bottom: 16px;
}
.my-3 {
    margin-top: 16px;
    margin-bottom: 16px;
}
.m-4 {
    margin: 24px;
}
.ml-4 {
    margin-left: 24px;
}
.mr-4 {
    margin-right: 24px;
}
.mx-4 {
    margin-left: 24px;
    margin-right: 24px;
}
.mt-4 {
    margin-top: 24px;
}
.mb-4 {
    margin-bottom: 24px;
}
.my-4 {
    margin-top: 24px;
    margin-bottom: 24px;
}
.m-5 {
    margin: 32px;
}
.ml-5 {
    margin-left: 32px;
}
.mr-5 {
    margin-right: 32px;
}
.mx-5 {
    margin-left: 32px;
    margin-right: 32px;
}
.mt-5 {
    margin-top: 32px;
}
.mb-5 {
    margin-bottom: 32px;
}
.my-5 {
    margin-top: 32px;
    margin-bottom: 32px;
}
.m-6 {
    margin: 40px;
}
.ml-6 {
    margin-left: 40px;
}
.mr-6 {
    margin-right: 40px;
}
.mx-6 {
    margin-left: 40px;
    margin-right: 40px;
}
.mt-6 {
    margin-top: 40px;
}
.mb-6 {
    margin-bottom: 40px;
}
.my-6 {
    margin-top: 40px;
    margin-bottom: 40px;
}
.m-7 {
    margin: 48px;
}
.ml-7 {
    margin-left: 48px;
}
.mr-7 {
    margin-right: 48px;
}
.mx-7 {
    margin-left: 48px;
    margin-right: 48px;
}
.mt-7 {
    margin-top: 48px;
}
.mb-7 {
    margin-bottom: 48px;
}
.my-7 {
    margin-top: 48px;
    margin-bottom: 48px;
}
.m-8 {
    margin: 56px;
}
.ml-8 {
    margin-left: 56px;
}
.mr-8 {
    margin-right: 56px;
}
.mx-8 {
    margin-left: 56px;
    margin-right: 56px;
}
.mt-8 {
    margin-top: 56px;
}
.mb-8 {
    margin-bottom: 56px;
}
.my-8 {
    margin-top: 56px;
    margin-bottom: 56px;
}
.m-0\.5 {
    margin: 2px;
}
.ml-0\.5 {
    margin-left: 2px;
}
.mr-0\.5 {
    margin-right: 2px;
}
.mx-0\.5 {
    margin-left: 2px;
    margin-right: 2px;
}
.mt-0\.5 {
    margin-top: 2px;
}
.mb-0\.5 {
    margin-bottom: 2px;
}
.my-0\.5 {
    margin-top: 2px;
    margin-bottom: 2px;
}
.uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.cursor-default {
    cursor:
        var(--default-cursor) 12 12,
        default;
}
.cursor-click-and-drag {
    cursor:
        var(--click-and-drag-cursor) 13 14,
        ew-resize;
}
.cursor-text {
    cursor:
        var(--text-cursor) 12 12,
        text;
}
.cursor-writing {
    cursor:
        var(--writing-cursor) 12 12,
        default;
}
.cursor-correct-mode {
    cursor:
        var(--correct-mode-cursor) 12 12,
        default;
}
.cursor-correct-tool {
    cursor:
        var(--correct-tool-cursor) 12 12,
        default;
}
.cursor-overdub {
    cursor:
        var(--overdub-cursor) 12 12,
        default;
}
.cursor-vertical-resize {
    cursor:
        var(--vertical-resize-cursor) 12 12,
        ns-resize;
}
.cursor-horizontal-resize {
    cursor:
        var(--horizontal-resize-cursor) 12 12,
        ew-resize;
}
.cursor-nwse-resize {
    cursor:
        var(--nwse-resize-cursor) 12 12,
        nwse-resize;
}
.cursor-nesw-resize {
    cursor:
        var(--nesw-resize-cursor) 12 12,
        nesw-resize;
}
.cursor-rotate {
    cursor:
        var(--rotate-cursor) 12 12,
        default;
}
.cursor-col-resize {
    cursor:
        var(--col-resize-cursor) 12 12,
        col-resize;
}
.cursor-row-resize {
    cursor:
        var(--row-resize-cursor) 12 12,
        row-resize;
}
.cursor-all-scroll {
    cursor:
        var(--all-scroll-cursor) 12 12,
        all-scroll;
}
.cursor-pointer {
    cursor:
        var(--pointer-cursor) 12 12,
        pointer;
}
.cursor-grab {
    cursor:
        var(--grab-cursor) 12 12,
        grab;
}
.cursor-grabbing {
    cursor:
        var(--grabbing-cursor) 12 12,
        grabbing;
}
.cursor-zoom-in {
    cursor:
        var(--zoom-in-cursor) 12 12,
        zoom-in;
}
.cursor-zoom-out {
    cursor:
        var(--zoom-out-cursor) 12 12,
        zoom-out;
}
.cursor-crosshair {
    cursor:
        var(--crosshair-cursor) 12 12,
        crosshair;
}
.cursor-crosshair-alt {
    cursor:
        var(--crosshair-alt-cursor) 12 12,
        crosshair;
}
.cursor-progress {
    cursor:
        var(--progress-cursor) 12 12,
        progress;
}
.cursor-copy {
    cursor:
        var(--copy-cursor) 12 12,
        copy;
}
.cursor-not-allowed {
    cursor:
        var(--not-allowed-cursor) 12 12,
        no-drop;
}
.cursor-help {
    cursor:
        var(--help-cursor) 12 12,
        help;
}
.cursor-trim-start {
    cursor:
        var(--trim-start-cursor) 12 12,
        default;
}
.cursor-trim-end {
    cursor:
        var(--trim-end-cursor) 12 12,
        default;
}
.cursor-blade {
    cursor:
        var(--blade-cursor) 12 12,
        text;
}
