:root,
html {
    /**
 * Grey/0-Background
 */
    --grey-0-base: rgba(255, 255, 255, 1);
    --grey-0: var(--grey-0-base);

    /**
 * Grey/0-Background
 */
    --dark-grey-0-base: rgba(26, 26, 26, 1);
    --dark-grey-0: var(--dark-grey-0-base);

    /**
 * Grey/5-Popover background
 */
    --grey-5-base: rgba(255, 255, 255, 1);
    --grey-5: var(--grey-5-base);

    /**
 * Grey/5-Popover background
 */
    --dark-grey-5-base: rgba(38, 38, 38, 1);
    --dark-grey-5: var(--dark-grey-5-base);

    /**
 * Grey/50-Surface
 */
    --grey-50-base: rgba(242, 242, 242, 1);
    --grey-50: var(--grey-50-base);

    /**
 * Grey/50-Surface
 */
    --dark-grey-50-base: rgba(43, 43, 43, 1);
    --dark-grey-50: var(--dark-grey-50-base);

    /**
 * Grey/200
 */
    --grey-200-base: rgba(204, 204, 204, 1);
    --grey-200: var(--grey-200-base);

    /**
 * Grey/200
 */
    --dark-grey-200-base: rgba(133, 133, 133, 1);
    --dark-grey-200: var(--dark-grey-200-base);

    /**
 * Grey/400-Unselected
 */
    --grey-400-base: rgba(133, 133, 133, 1);
    --grey-400: var(--grey-400-base);

    /**
 * Grey/400-Unselected
 */
    --dark-grey-400-base: rgba(204, 204, 204, 1);
    --dark-grey-400: var(--dark-grey-400-base);

    /**
 * Grey/800-Primary
 */
    --grey-800-base: rgba(52, 52, 52, 1);
    --grey-800: var(--grey-800-base);

    /**
 * Grey/800-Primary
 */
    --dark-grey-800-base: rgba(230, 230, 230, 1);
    --dark-grey-800: var(--dark-grey-800-base);

    /**
 * Grey/50-A-Hover
 */
    --grey-50-a-base: rgba(0, 0, 0, 0.05000000074505806);
    --grey-50-a: var(--grey-50-a-base);

    /**
 * Grey/50-A-Hover
 */
    --dark-grey-50-a-base: rgba(255, 255, 255, 0.07999999821186066);
    --dark-grey-50-a: var(--dark-grey-50-a-base);

    /**
 * Grey/100-A-Border
 */
    --grey-100-a-base: rgba(0, 0, 0, 0.10000000149011612);
    --grey-100-a: var(--grey-100-a-base);

    /**
 * Grey/100-A-Border
 */
    --dark-grey-100-a-base: rgba(255, 255, 255, 0.1599999964237213);
    --dark-grey-100-a: var(--dark-grey-100-a-base);

    /**
 * Grey/400-A-Scrim
 */
    --grey-400-a-base: rgba(0, 0, 0, 0.44999998807907104);
    --grey-400-a: var(--grey-400-a-base);

    /**
 * Grey/400-A-Scrim
 */
    --dark-grey-400-a-base: rgba(0, 0, 0, 0.44999998807907104);
    --dark-grey-400-a: var(--dark-grey-400-a-base);

    /**
 * Cyan/200
 */
    --cyan-200-base: rgba(114, 190, 255, 1);
    --cyan-200: var(--cyan-200-base);

    /**
 * Cyan/200
 */
    --dark-cyan-200-base: rgba(0, 122, 183, 1);
    --dark-cyan-200: var(--dark-cyan-200-base);

    /**
 * Cyan/500-Selected element
 */
    --cyan-500-base: rgba(0, 170, 255, 1);
    --cyan-500: var(--cyan-500-base);

    /**
 * Cyan/500-Selected element
 */
    --dark-cyan-500-base: rgba(0, 170, 255, 1);
    --dark-cyan-500: var(--dark-cyan-500-base);

    /**
 * Cyan/800
 */
    --cyan-800-base: rgba(0, 122, 183, 1);
    --cyan-800: var(--cyan-800-base);

    /**
 * Cyan/800
 */
    --dark-cyan-800-base: rgba(114, 190, 255, 1);
    --dark-cyan-800: var(--dark-cyan-800-base);

    /**
 * Cyan/500-A-Text selection
 */
    --cyan-500-a-base: rgba(0, 170, 255, 0.15000000596046448);
    --cyan-500-a: var(--cyan-500-a-base);

    /**
 * Cyan/500-A-Text selection
 */
    --dark-cyan-500-a-base: rgba(0, 170, 255, 0.23999999463558197);
    --dark-cyan-500-a: var(--dark-cyan-500-a-base);

    /**
 * Blue/200
 */
    --blue-200-base: rgba(111, 148, 236, 1);
    --blue-200: var(--blue-200-base);

    /**
 * Blue/200
 */
    --dark-blue-200-base: rgba(36, 70, 146, 1);
    --dark-blue-200: var(--dark-blue-200-base);

    /**
 * Blue/800-Write mode
 */
    --blue-800-base: rgba(36, 70, 146, 1);
    --blue-800: var(--blue-800-base);

    /**
 * Blue/800-Write mode
 */
    --dark-blue-800-base: rgba(111, 148, 236, 1);
    --dark-blue-800: var(--dark-blue-800-base);

    /**
 * Blue/500-Primary action
 */
    --blue-500-base: rgba(0, 69, 229, 1);
    --blue-500: var(--blue-500-base);

    /**
 * Blue/500-Primary action
 */
    --dark-blue-500-base: rgba(111, 148, 236, 1);
    --dark-blue-500: var(--dark-blue-500-base);

    /**
 * Blue/500-A-Highlight
 */
    --blue-500-a-base: rgba(0, 69, 229, 0.15000000596046448);
    --blue-500-a: var(--blue-500-a-base);

    /**
 * Blue/500-A-Highlight
 */
    --dark-blue-500-a-base: rgba(87, 123, 214, 0.44999998807907104);
    --dark-blue-500-a: var(--dark-blue-500-a-base);

    /**
 * Purple/200
 */
    --purple-200-base: rgba(204, 153, 255, 1);
    --purple-200: var(--purple-200-base);

    /**
 * Purple/200
 */
    --dark-purple-200-base: rgba(102, 0, 204, 1);
    --dark-purple-200: var(--dark-purple-200-base);

    /**
 * Purple/500-Script elements
 */
    --purple-500-base: rgba(153, 51, 255, 1);
    --purple-500: var(--purple-500-base);

    /**
 * Purple/500-Script elements
 */
    --dark-purple-500-base: rgba(204, 153, 255, 1);
    --dark-purple-500: var(--dark-purple-500-base);

    /**
 * Purple/800-Speaker label
 */
    --purple-800-base: rgba(102, 0, 204, 1);
    --purple-800: var(--purple-800-base);

    /**
 * Purple/800-Speaker label
 */
    --dark-purple-800-base: rgba(204, 153, 255, 1);
    --dark-purple-800: var(--dark-purple-800-base);

    /**
 * Purple/500-A-Highlight
 */
    --purple-500-a-base: rgba(153, 51, 255, 0.20000000298023224);
    --purple-500-a: var(--purple-500-a-base);

    /**
 * Purple/500-A-Highlight
 */
    --dark-purple-500-a-base: rgba(184, 131, 236, 0.44999998807907104);
    --dark-purple-500-a: var(--dark-purple-500-a-base);

    /**
 * Pink/200
 */
    --pink-200-base: rgba(245, 96, 132, 1);
    --pink-200: var(--pink-200-base);

    /**
 * Pink/200
 */
    --dark-pink-200-base: rgba(197, 0, 82, 1);
    --dark-pink-200: var(--dark-pink-200-base);

    /**
 * Pink/500
 */
    --pink-500-base: rgba(255, 0, 106, 1);
    --pink-500: var(--pink-500-base);

    /**
 * Pink/500
 */
    --dark-pink-500-base: rgba(255, 0, 106, 1);
    --dark-pink-500: var(--dark-pink-500-base);

    /**
 * Pink/800-Speaker label
 */
    --pink-800-base: rgba(197, 0, 82, 1);
    --pink-800: var(--pink-800-base);

    /**
 * Pink/800-Speaker label
 */
    --dark-pink-800-base: rgba(245, 96, 132, 1);
    --dark-pink-800: var(--dark-pink-800-base);

    /**
 * Pink/500-A-Highlight
 */
    --pink-500-a-base: rgba(255, 0, 106, 0.2199999988079071);
    --pink-500-a: var(--pink-500-a-base);

    /**
 * Pink/500-A-Highlight
 */
    --dark-pink-500-a-base: rgba(225, 90, 146, 0.44999998807907104);
    --dark-pink-500-a: var(--dark-pink-500-a-base);

    /**
 * Red/200
 */
    --red-200-base: rgba(250, 88, 88, 1);
    --red-200: var(--red-200-base);

    /**
 * Red/200
 */
    --dark-red-200-base: rgba(168, 0, 0, 1);
    --dark-red-200: var(--dark-red-200-base);

    /**
 * Red/500-Attention-Recording
 */
    --red-500-base: rgba(217, 1, 1, 1);
    --red-500: var(--red-500-base);

    /**
 * Red/500-Attention-Recording
 */
    --dark-red-500-base: rgba(250, 88, 88, 1);
    --dark-red-500: var(--dark-red-500-base);

    /**
 * Red/800-Speaker label
 */
    --red-800-base: rgba(168, 0, 0, 1);
    --red-800: var(--red-800-base);

    /**
 * Red/800-Speaker label
 */
    --dark-red-800-base: rgba(250, 88, 88, 1);
    --dark-red-800: var(--dark-red-800-base);

    /**
 * Red/500-A-Highlight
 */
    --red-500-a-base: rgba(255, 0, 0, 0.25);
    --red-500-a: var(--red-500-a-base);

    /**
 * Red/500-A-Highlight
 */
    --dark-red-500-a-base: rgba(241, 30, 30, 0.3499999940395355);
    --dark-red-500-a: var(--dark-red-500-a-base);

    /**
 * Orange/200
 */
    --orange-200-base: rgba(255, 137, 114, 1);
    --orange-200: var(--orange-200-base);

    /**
 * Orange/200
 */
    --dark-orange-200-base: rgba(222, 73, 28, 1);
    --dark-orange-200: var(--dark-orange-200-base);

    /**
 * Orange/500
 */
    --orange-500-base: rgba(255, 85, 0, 1);
    --orange-500: var(--orange-500-base);

    /**
 * Orange/500
 */
    --dark-orange-500-base: rgba(255, 85, 0, 1);
    --dark-orange-500: var(--dark-orange-500-base);

    /**
 * Orange/800-Speaker label
 */
    --orange-800-base: rgba(222, 73, 28, 1);
    --orange-800: var(--orange-800-base);

    /**
 * Orange/800-Speaker label
 */
    --dark-orange-800-base: rgba(255, 137, 114, 1);
    --dark-orange-800: var(--dark-orange-800-base);

    /**
 * Orange/500-A-Highlight
 */
    --orange-500-a-base: rgba(255, 85, 0, 0.25);
    --orange-500-a: var(--orange-500-a-base);

    /**
 * Orange/500-A-Highlight
 */
    --dark-orange-500-a-base: rgba(241, 109, 44, 0.33000001311302185);
    --dark-orange-500-a: var(--dark-orange-500-a-base);

    /**
 * Amber/200-Search result selected
 */
    --amber-200-base: rgba(255, 178, 114, 1);
    --amber-200: var(--amber-200-base);

    /**
 * Amber/200-Search result selected
 */
    --dark-amber-200-base: rgba(168, 103, 0, 1);
    --dark-amber-200: var(--dark-amber-200-base);

    /**
 * Amber/500-Caution
 */
    --amber-500-base: rgba(255, 153, 0, 1);
    --amber-500: var(--amber-500-base);

    /**
 * Amber/500-Caution
 */
    --dark-amber-500-base: rgba(255, 178, 114, 1);
    --dark-amber-500: var(--dark-amber-500-base);

    /**
 * Amber/800-Comment
 */
    --amber-800-base: rgba(168, 103, 0, 1);
    --amber-800: var(--amber-800-base);

    /**
 * Amber/800-Comment
 */
    --dark-amber-800-base: rgba(255, 178, 114, 1);
    --dark-amber-800: var(--dark-amber-800-base);

    /**
 * Amber/500-A-Highlight
 */
    --amber-500-a-base: rgba(255, 153, 0, 0.25);
    --amber-500-a: var(--amber-500-a-base);

    /**
 * Amber/500-A-Highlight
 */
    --dark-amber-500-a-base: rgba(238, 163, 52, 0.33000001311302185);
    --dark-amber-500-a: var(--dark-amber-500-a-base);

    /**
 * Yellow/200-Search result unselected
 */
    --yellow-200-base: rgba(254, 245, 114, 1);
    --yellow-200: var(--yellow-200-base);

    /**
 * Yellow/200-Search result unselected
 */
    --dark-yellow-200-base: rgba(129, 116, 18, 1);
    --dark-yellow-200: var(--dark-yellow-200-base);

    /**
 * Yellow/500
 */
    --yellow-500-base: rgba(255, 238, 0, 1);
    --yellow-500: var(--yellow-500-base);

    /**
 * Yellow/500
 */
    --dark-yellow-500-base: rgba(255, 238, 0, 1);
    --dark-yellow-500: var(--dark-yellow-500-base);

    /**
 * Yellow/800
 */
    --yellow-800-base: rgba(129, 116, 18, 1);
    --yellow-800: var(--yellow-800-base);

    /**
 * Yellow/800
 */
    --dark-yellow-800-base: rgba(254, 245, 114, 1);
    --dark-yellow-800: var(--dark-yellow-800-base);

    /**
 * Yellow/500-A-Highlight
 */
    --yellow-500-a-base: rgba(255, 238, 0, 0.25);
    --yellow-500-a: var(--yellow-500-a-base);

    /**
 * Yellow/500-A-Highlight
 */
    --dark-yellow-500-a-base: rgba(255, 238, 0, 0.25);
    --dark-yellow-500-a: var(--dark-yellow-500-a-base);

    /**
 * Green/200
 */
    --green-200-base: rgba(114, 179, 114, 1);
    --green-200: var(--green-200-base);

    /**
 * Green/200
 */
    --dark-green-200-base: rgba(0, 120, 0, 1);
    --dark-green-200: var(--dark-green-200-base);

    /**
 * Green/500
 */
    --green-500-base: rgba(0, 155, 0, 1);
    --green-500: var(--green-500-base);

    /**
 * Green/500
 */
    --dark-green-500-base: rgba(0, 155, 0, 1);
    --dark-green-500: var(--dark-green-500-base);

    /**
 * Green/800-Correction suggestion
 */
    --green-800-base: rgba(0, 120, 0, 1);
    --green-800: var(--green-800-base);

    /**
 * Green/800-Correction suggestion
 */
    --dark-green-800-base: rgba(114, 179, 114, 1);
    --dark-green-800: var(--dark-green-800-base);

    /**
 * Green/500-A-Highlight
 */
    --green-500-a-base: rgba(0, 155, 0, 0.2199999988079071);
    --green-500-a: var(--green-500-a-base);

    /**
 * Green/500-A-Highlight
 */
    --dark-green-500-a-base: rgba(28, 165, 28, 0.3100000023841858);
    --dark-green-500-a: var(--dark-green-500-a-base);

    /**
 * Brown/200
 */
    --brown-200-base: rgba(173, 123, 99, 1);
    --brown-200: var(--brown-200-base);

    /**
 * Brown/200
 */
    --dark-brown-200-base: rgba(88, 62, 49, 1);
    --dark-brown-200: var(--dark-brown-200-base);

    /**
 * Brown/800
 */
    --brown-800-base: rgba(88, 62, 49, 1);
    --brown-800: var(--brown-800-base);

    /**
 * Brown/800
 */
    --dark-brown-800-base: rgba(173, 123, 99, 1);
    --dark-brown-800: var(--dark-brown-800-base);

    /**
 * Chrome/0-Recessed surface
 */
    --chrome-0-recessed-base: rgba(250, 248, 247, 1);
    --chrome-0-recessed: var(--chrome-0-recessed-base);

    /**
 * Chrome/0-Recessed surface
 */
    --dark-chrome-0-recessed-base: rgba(8, 8, 8, 1);
    --dark-chrome-0-recessed: var(--dark-chrome-0-recessed-base);

    /**
 * Chrome/0-Elevated surface
 */
    --chrome-0-elevated-base: rgba(255, 255, 255, 1);
    --chrome-0-elevated: var(--chrome-0-elevated-base);

    /**
 * Chrome/0-Elevated surface
 */
    --dark-chrome-0-elevated-base: rgba(29, 31, 32, 1);
    --dark-chrome-0-elevated: var(--dark-chrome-0-elevated-base);

    /**
 * Grey/100-A-Border inverse
 */
    --grey-100-a-inverse-base: rgba(255, 255, 255, 0.15000000596046448);
    --grey-100-a-inverse: var(--grey-100-a-inverse-base);

    /**
 * Grey/100-A-Border inverse
 */
    --dark-grey-100-a-inverse-base: rgba(0, 0, 0, 0.15000000596046448);
    --dark-grey-100-a-inverse: var(--dark-grey-100-a-inverse-base);

    /**
 * Drop shadow/Soft
 */
    --drop-shadow-soft-base: rgba(0, 0, 0, 0.07999999821186066);
    --drop-shadow-soft: var(--drop-shadow-soft-base);

    /**
 * Drop shadow/Soft
 */
    --dark-drop-shadow-soft-base: rgba(0, 0, 0, 0.8399999737739563);
    --dark-drop-shadow-soft: var(--dark-drop-shadow-soft-base);

    /**
 * Drop shadow/Medium
 */
    --drop-shadow-medium-base: rgba(0, 0, 0, 0.11999999731779099);
    --drop-shadow-medium: var(--drop-shadow-medium-base);

    /**
 * Drop shadow/Medium
 */
    --dark-drop-shadow-medium-base: rgba(0, 0, 0, 0.8799999952316284);
    --dark-drop-shadow-medium: var(--dark-drop-shadow-medium-base);

    /**
 * Drop shadow/Hard
 */
    --drop-shadow-hard-base: rgba(0, 0, 0, 0.1599999964237213);
    --drop-shadow-hard: var(--drop-shadow-hard-base);

    /**
 * Drop shadow/Hard
 */
    --dark-drop-shadow-hard-base: rgba(0, 0, 0, 0.9200000166893005);
    --dark-drop-shadow-hard: var(--dark-drop-shadow-hard-base);

    /**
 * Upgrade/Primary
 */
    --upgrade-primary-base: rgba(102, 0, 204, 1);
    --upgrade-primary: var(--upgrade-primary-base);

    /**
 * Upgrade/Primary
 */
    --dark-upgrade-primary-base: rgba(204, 153, 255, 1);
    --dark-upgrade-primary: var(--dark-upgrade-primary-base);

    /**
 * Upgrade/Background
 */
    --upgrade-background-base: rgba(247, 242, 252, 1);
    --upgrade-background: var(--upgrade-background-base);

    /**
 * Upgrade/Background
 */
    --dark-upgrade-background-base: rgba(97, 73, 121, 1);
    --dark-upgrade-background: var(--dark-upgrade-background-base);

    /**
 * Upgrade/Button Background
 */
    --upgrade-button-background-base: rgba(255, 255, 255, 1);
    --upgrade-button-background: var(--upgrade-button-background-base);

    /**
 * Upgrade/Button Background
 */
    --dark-upgrade-button-background-base: rgba(255, 255, 255, 1);
    --dark-upgrade-button-background: var(--dark-upgrade-button-background-base);

    /**
 * Drop shadow/None
 */
    --drop-shadow-none-base: rgba(0, 0, 0, 0);
    --drop-shadow-none: var(--drop-shadow-none-base);

    /**
 * Drop shadow/None
 */
    --dark-drop-shadow-none-base: rgba(0, 0, 0, 0);
    --dark-drop-shadow-none: var(--dark-drop-shadow-none-base);

    /**
 * Waveform/Default
 */
    --waveform-default-base: rgba(194, 194, 194, 1);
    --waveform-default: var(--waveform-default-base);

    /**
 * Waveform/Default
 */
    --dark-waveform-default-base: rgba(95, 95, 95, 1);
    --dark-waveform-default: var(--dark-waveform-default-base);

    /**
 * Waveform/Selected
 */
    --waveform-selected-base: rgba(114, 123, 128, 1);
    --waveform-selected: var(--waveform-selected-base);

    /**
 * Waveform/Selected
 */
    --dark-waveform-selected-base: rgba(154, 169, 176, 1);
    --dark-waveform-selected: var(--dark-waveform-selected-base);

    /**
 * Waveform/Hover
 */
    --waveform-base: rgba(123, 123, 123, 1);
    --waveform: var(--waveform-base);

    /**
 * Waveform/Hover
 */
    --dark-waveform-base: rgba(229, 229, 229, 1);
    --dark-waveform: var(--dark-waveform-base);

    /**
 * Cyan/600-Focus ring
 */
    --cyan-600-base: rgba(0, 154, 231, 1);
    --cyan-600: var(--cyan-600-base);

    /**
 * Cyan/600-Focus ring
 */
    --dark-cyan-600-base: rgba(0, 170, 255, 1);
    --dark-cyan-600: var(--dark-cyan-600-base);

    /**
 * Grey/30-A-Input background
 */
    --grey-30-a-base: rgba(0, 0, 0, 0.029999999329447746);
    --grey-30-a: var(--grey-30-a-base);

    /**
 * Grey/30-A-Input background
 */
    --dark-grey-30-a-base: rgba(255, 255, 255, 0.10000000149011612);
    --dark-grey-30-a: var(--dark-grey-30-a-base);

    /**
 * State/Inactive
 */
    --state-inactive-base: 0.3;
    --state-inactive: var(--state-inactive-base);

    /**
 * State/Inactive
 */
    --dark-state-inactive-base: 0.42;
    --dark-state-inactive: var(--dark-state-inactive-base);

    /**
 * Static/Black
 */
    --static-black-base: rgba(26, 26, 26, 1);
    --static-black: var(--static-black-base);

    /**
 * Static/Black
 */
    --dark-static-black-base: rgba(26, 26, 26, 1);
    --dark-static-black: var(--dark-static-black-base);

    /**
 * Static/White
 */
    --static-white-base: rgba(255, 255, 255, 1);
    --static-white: var(--static-white-base);

    /**
 * Static/White
 */
    --dark-static-white-base: rgba(255, 255, 255, 1);
    --dark-static-white: var(--dark-static-white-base);

    /**
 * 
 */
    --label-1-medium-font-family: 'Inter', system-ui, sans-serif;
    --label-1-medium-font-size: 12px;
    --label-1-medium-font-style: normal;
    --label-1-medium-font-variant: normal;
    --label-1-medium-font-weight: 570;
    --label-1-medium-letter-spacing: 0.00119999997317791px;
    --label-1-medium-line-height: 16px;
    --label-1-medium-text-align: left;
    --label-1-medium-text-decoration: none;
    --label-1-medium-text-transform: none;
    --label-1-medium-vertical-align: top;

    /**
 * 
 */
    --label-1-regular-font-family: 'Inter', system-ui, sans-serif;
    --label-1-regular-font-size: 12px;
    --label-1-regular-font-style: normal;
    --label-1-regular-font-variant: normal;
    --label-1-regular-font-weight: 400;
    --label-1-regular-letter-spacing: 0.00119999997317791px;
    --label-1-regular-line-height: 16px;
    --label-1-regular-text-align: left;
    --label-1-regular-text-decoration: none;
    --label-1-regular-text-transform: none;
    --label-1-regular-vertical-align: top;

    /**
 * 
 */
    --label-2-medium-font-family: 'Inter', system-ui, sans-serif;
    --label-2-medium-font-size: 11px;
    --label-2-medium-font-style: normal;
    --label-2-medium-font-variant: normal;
    --label-2-medium-font-weight: 570;
    --label-2-medium-letter-spacing: 0.055px;
    --label-2-medium-line-height: 16px;
    --label-2-medium-text-align: left;
    --label-2-medium-text-decoration: none;
    --label-2-medium-text-transform: none;
    --label-2-medium-vertical-align: top;

    /**
 * Used in the script
 */
    --body-2-script-regular-font-family: 'Inter', system-ui, sans-serif;
    --body-2-script-regular-font-size: 14px;
    --body-2-script-regular-font-style: normal;
    --body-2-script-regular-font-variant: normal;
    --body-2-script-regular-font-weight: 400;
    --body-2-script-regular-letter-spacing: -0.0840000033378601px;
    --body-2-script-regular-line-height: 32px;
    --body-2-script-regular-text-align: left;
    --body-2-script-regular-text-decoration: none;
    --body-2-script-regular-text-transform: none;
    --body-2-script-regular-vertical-align: top;

    /**
 * Used in the script
 */
    --body-1-script-regular-font-family: 'Inter', system-ui, sans-serif;
    --body-1-script-regular-font-size: 16px;
    --body-1-script-regular-font-style: normal;
    --body-1-script-regular-font-variant: normal;
    --body-1-script-regular-font-weight: 400;
    --body-1-script-regular-letter-spacing: -0.17600000381469727px;
    --body-1-script-regular-line-height: 32px;
    --body-1-script-regular-text-align: left;
    --body-1-script-regular-text-decoration: none;
    --body-1-script-regular-text-transform: none;
    --body-1-script-regular-vertical-align: top;

    /**
 * 
 */
    --headline-4-font-family: 'Inter', system-ui, sans-serif;
    --headline-4-font-size: 18px;
    --headline-4-font-style: normal;
    --headline-4-font-variant: normal;
    --headline-4-font-weight: 500;
    --headline-4-letter-spacing: -0.2519999957084656px;
    --headline-4-line-height: 32px;
    --headline-4-text-align: left;
    --headline-4-text-decoration: none;
    --headline-4-text-transform: none;
    --headline-4-vertical-align: top;

    /**
 * 
 */
    --headline-3-font-family: 'Inter', system-ui, sans-serif;
    --headline-3-font-size: 24px;
    --headline-3-font-style: normal;
    --headline-3-font-variant: normal;
    --headline-3-font-weight: 400;
    --headline-3-letter-spacing: -0.4559999942779541px;
    --headline-3-line-height: 32px;
    --headline-3-text-align: left;
    --headline-3-text-decoration: none;
    --headline-3-text-transform: none;
    --headline-3-vertical-align: top;

    /**
 * 
 */
    --headline-2-font-family: 'Inter', system-ui, sans-serif;
    --headline-2-font-size: 36px;
    --headline-2-font-style: normal;
    --headline-2-font-variant: normal;
    --headline-2-font-weight: 400;
    --headline-2-letter-spacing: -0.7920000171661377px;
    --headline-2-line-height: 48px;
    --headline-2-text-align: left;
    --headline-2-text-decoration: none;
    --headline-2-text-transform: none;
    --headline-2-vertical-align: top;

    /**
 * 
 */
    --headline-1-font-family: 'Inter', system-ui, sans-serif;
    --headline-1-font-size: 48px;
    --headline-1-font-style: normal;
    --headline-1-font-variant: normal;
    --headline-1-font-weight: 400;
    --headline-1-letter-spacing: -1.0560000228881836px;
    --headline-1-line-height: 64px;
    --headline-1-text-align: left;
    --headline-1-text-decoration: none;
    --headline-1-text-transform: none;
    --headline-1-vertical-align: top;

    /**
 * 
 */
    --label-2-regular-font-family: 'Inter', system-ui, sans-serif;
    --label-2-regular-font-size: 11px;
    --label-2-regular-font-style: normal;
    --label-2-regular-font-variant: normal;
    --label-2-regular-font-weight: 400;
    --label-2-regular-letter-spacing: 0.055px;
    --label-2-regular-line-height: 16px;
    --label-2-regular-text-align: left;
    --label-2-regular-text-decoration: none;
    --label-2-regular-text-transform: none;
    --label-2-regular-vertical-align: top;

    /**
 * 
 */
    --badge-font-family: 'Inter', system-ui, sans-serif;
    --badge-font-size: 9px;
    --badge-font-style: normal;
    --badge-font-variant: normal;
    --badge-font-weight: 500;
    --badge-letter-spacing: 0.14400000214576722px;
    --badge-line-height: 16px;
    --badge-text-align: left;
    --badge-text-decoration: none;
    --badge-text-transform: none;
    --badge-vertical-align: top;

    /**
 * Used in the script
 */
    --body-1-script-medium-font-family: 'Inter', system-ui, sans-serif;
    --body-1-script-medium-font-size: 16px;
    --body-1-script-medium-font-style: normal;
    --body-1-script-medium-font-variant: normal;
    --body-1-script-medium-font-weight: 570;
    --body-1-script-medium-letter-spacing: -0.17600000381469727px;
    --body-1-script-medium-line-height: 32px;
    --body-1-script-medium-text-align: left;
    --body-1-script-medium-text-decoration: none;
    --body-1-script-medium-text-transform: none;
    --body-1-script-medium-vertical-align: top;

    /**
 * Buttons, Pop-up dialogs,
 */
    --elevation-5: 0px 8px 16px 0px rgba(0, 0, 0, 0.12), 0px 8px 8px 0px rgba(0, 0, 0, 0.08);

    /**
 * Drop shadow version to use with CSS filter and SVG elements
 */
    --elevation-5-filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.08));

    /**
 * Large Surfaces, Modals
 */
    --elevation-10: 0px 120px 136px 0px rgba(0, 0, 0, 0.12),
        0px 16px 38px 0px rgba(0, 0, 0, 0.16);

    /**
 * Drop shadow version to use with CSS filter and SVG elements
 */
    --elevation-10-filter: drop-shadow(0px 120px 136px rgba(0, 0, 0, 0.12))
        drop-shadow(0px 16px 38px rgba(0, 0, 0, 0.16));

    /**
 * Tooltip
 */
    --elevation-1: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);

    /**
 * Drop shadow version to use with CSS filter and SVG elements
 */
    --elevation-1-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));

    /**
 * Used in the script
 */
    --body-2-script-medium-font-family: 'Inter', system-ui, sans-serif;
    --body-2-script-medium-font-size: 14px;
    --body-2-script-medium-font-style: normal;
    --body-2-script-medium-font-variant: normal;
    --body-2-script-medium-font-weight: 500;
    --body-2-script-medium-letter-spacing: -0.0840000033378601px;
    --body-2-script-medium-line-height: 32px;
    --body-2-script-medium-text-align: left;
    --body-2-script-medium-text-decoration: none;
    --body-2-script-medium-text-transform: none;
    --body-2-script-medium-vertical-align: top;

    /**
 * Used outside of the script
 */
    --body-1-regular-font-family: 'Inter', system-ui, sans-serif;
    --body-1-regular-font-size: 16px;
    --body-1-regular-font-style: normal;
    --body-1-regular-font-variant: normal;
    --body-1-regular-font-weight: 400;
    --body-1-regular-letter-spacing: -0.17600000381469727px;
    --body-1-regular-line-height: 24px;
    --body-1-regular-text-align: left;
    --body-1-regular-text-decoration: none;
    --body-1-regular-text-transform: none;
    --body-1-regular-vertical-align: top;

    /**
 * Used outside of the script
 */
    --body-1-medium-font-family: 'Inter', system-ui, sans-serif;
    --body-1-medium-font-size: 16px;
    --body-1-medium-font-style: normal;
    --body-1-medium-font-variant: normal;
    --body-1-medium-font-weight: 500;
    --body-1-medium-letter-spacing: -0.17600000381469727px;
    --body-1-medium-line-height: 24px;
    --body-1-medium-text-align: left;
    --body-1-medium-text-decoration: none;
    --body-1-medium-text-transform: none;
    --body-1-medium-vertical-align: top;

    /**
 * Used outside of the script
 */
    --body-2-regular-font-family: 'Inter', system-ui, sans-serif;
    --body-2-regular-font-size: 14px;
    --body-2-regular-font-style: normal;
    --body-2-regular-font-variant: normal;
    --body-2-regular-font-weight: 400;
    --body-2-regular-letter-spacing: -0.0840000033378601px;
    --body-2-regular-line-height: 21px;
    --body-2-regular-text-align: left;
    --body-2-regular-text-decoration: none;
    --body-2-regular-text-transform: none;
    --body-2-regular-vertical-align: top;

    /**
 * Used outside of the script
 */
    --body-2-medium-font-family: 'Inter', system-ui, sans-serif;
    --body-2-medium-font-size: 14px;
    --body-2-medium-font-style: normal;
    --body-2-medium-font-variant: normal;
    --body-2-medium-font-weight: 500;
    --body-2-medium-letter-spacing: -0.0840000033378601px;
    --body-2-medium-line-height: 21px;
    --body-2-medium-text-align: left;
    --body-2-medium-text-decoration: none;
    --body-2-medium-text-transform: none;
    --body-2-medium-vertical-align: top;
}
