@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('./src/fonts/variable/InterVariable.woff2') format('woff2');
}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 100 900;
    font-display: swap;
    src: url('./src/fonts/variable/InterVariable-Italic.woff2') format('woff2');
}

svg * {
    vector-effect: non-scaling-stroke;
}

:where(svg *) {
    stroke-width: 1px;
}

.icon-1x svg,
.icon-2x svg {
    height: 100%;
    width: 100%;
}

.icon-2x {
    display: none !important;
}

@media only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 192dpi) {
    /* Style Rules */
    .icon-1x {
        display: none !important;
    }

    .icon-2x {
        display: flex !important;
    }
}
