html {
    overflow: hidden;
    --color-descript-blue: #0062ff;
    --color-descript-blue-rgb: 0, 98, 255;
    --color-descript-blue-2pct: rgba(0, 98, 255, 0.02);
    --color-descript-blue-5pct: rgba(0, 98, 255, 0.05);
    --color-descript-blue-10pct: rgba(0, 98, 255, 0.1);
    --color-descript-blue-15pct: rgba(0, 98, 255, 0.15);
    --color-descript-blue-20pct: rgba(0, 98, 255, 0.2);
    --color-descript-blue-30pct: rgba(0, 98, 255, 0.3);
    --color-descript-blue-40pct: rgba(0, 98, 255, 0.4);
    --color-descript-blue-70pct: rgba(0, 98, 255, 0.7);
    --color-descript-blue-90pct: rgba(0, 98, 255, 0.9);
    --color-descript-blue-darker-10pct: #0049e6;
    --color-descript-blue-upgrade-from-creator-text: #0073ff;

    --color-descript-light-blue-highlight: rgba(165, 240, 255, 1);
    --color-descript-blue-10pct-hex: #e1ebfb;
    --color-descript-light-blue-rgb: 58, 178, 255;
    --color-descript-light-blue: rgb(var(--color-descript-light-blue-rgb)); /* #3AB2FF */
    --color-descript-light-blue-60pct: rgba(58, 178, 255, 0.6);

    --color-descript-purple: rgb(145, 102, 255);
    --color-descript-purple-90pct: rgba(145, 102, 255, 0.9);
    --color-descript-purple-40pct: rgba(145, 102, 255, 0.4);
    --color-descript-timeline-waveform-purple: #404089;

    --color-descript-pink: rgb(222, 40, 152);
    --color-descript-pink-filler: #bf46be;
    --color-descript-pink-icon: rgb(211, 95, 216);
    --color-descript-pink-30pct: rgba(235, 197, 255, 0.3);

    --color-pro-banner-blue: rgba(188, 73, 194, 0.1);

    --color-descript-red: #ff365b;
    --color-descript-red-80pct: rgba(255, 54, 91, 0.8);
    --color-descript-red-30pct: rgba(255, 54, 91, 0.3);
    --color-descript-red-20pct: rgba(255, 54, 91, 0.2);
    --color-descript-red-10pct: rgba(255, 54, 91, 0.1);
    --color-descript-red-5pct: rgba(255, 54, 91, 0.05);
    --color-descript-red-dark-hex: rgb(176, 7, 52);
    --color-descript-red-background-light: #ffd1b9;

    --color-descript-orange: #ffa424;
    --color-descript-orange-upgrade-from-free-text: #ff873b;

    --color-descript-yellow-highlight: rgb(100%, 100%, 44%);
    --color-descript-yellow-background: #ffe066;
    --color-descript-yellow-background-light: #fff0cc;
    --color-descript-yellow-60pct-hex: #9a7000;
    --color-descript-yellow: rgba(255, 180, 0, 1);
    --color-descript-yellow-80pct: rgba(255, 180, 0, 0.8);
    --color-descript-yellow-50pct: rgba(255, 180, 0, 0.5);
    --color-descript-yellow-40pct: rgba(255, 180, 0, 0.4);
    --color-descript-yellow-30pct: rgba(255, 180, 0, 0.3);
    --color-descript-yellow-20pct: rgba(255, 180, 0, 0.2);

    --color-descript-pure-green: rgb(51, 217, 0);
    --color-descript-pure-green-20pct: rgba(51, 217, 0, 0.2);
    --color-descript-pure-green-10pct: rgba(51, 217, 0, 0.1);
    --color-descript-green: rgb(0, 209, 178); /* #00D1B2 */
    --color-descript-green-35pct: rgba(0, 209, 178, 0.35);
    --color-descript-green-20pct: rgba(0, 209, 178, 0.2);
    --color-descript-green-15pct: rgba(0, 209, 178, 0.2);
    --color-descript-green-10pct: rgba(0, 209, 178, 0.1);
    --color-descript-green-70pct: rgba(0, 209, 178, 0.7);

    --color-black-5pct-hex: #f2f2f2;
    --color-black-10pct-hex: #e1e1e1;
    --color-black-30pct-hex: #b3b3b3;
    --color-black-40pct-hex: #979797;
    --color-black-60pct-hex: #666666;
    --color-black-80pct-hex: #333333;

    --color-black-0pct: rgba(0, 0, 0, 0);
    --color-black-2pct: rgba(0, 0, 0, 0.02);
    --color-black-3pct: rgba(0, 0, 0, 0.03);
    --color-black-4pct: rgba(0, 0, 0, 0.04);
    --color-black-5pct: rgba(0, 0, 0, 0.05);
    --color-black-6pct: rgba(0, 0, 0, 0.06);
    --color-black-7pct: rgba(0, 0, 0, 0.07);
    --color-black-8pct: rgba(0, 0, 0, 0.08);
    --color-black-10pct: rgba(0, 0, 0, 0.1);
    --color-black-12pct: rgba(0, 0, 0, 0.12);
    --color-black-15pct: rgba(0, 0, 0, 0.15);
    --color-black-16pct: rgba(0, 0, 0, 0.16);
    --color-black-19pct: rgba(0, 0, 0, 0.19);
    --color-black-20pct: rgba(0, 0, 0, 0.2);
    --color-black-24pct: rgba(0, 0, 0, 0.24);
    --color-black-25pct: rgba(0, 0, 0, 0.25);
    --color-black-30pct: rgba(0, 0, 0, 0.3);
    --color-black-33pct: rgba(0, 0, 0, 0.33);
    --color-black-35pct: rgba(0, 0, 0, 0.35);
    --color-black-37pct: rgba(0, 0, 0, 0.37);
    --color-black-40pct: rgba(0, 0, 0, 0.4);
    --color-black-50pct: rgba(0, 0, 0, 0.5);
    --color-black-51pct: rgba(0, 0, 0, 0.51);
    --color-black-55pct: rgba(0, 0, 0, 0.55);
    --color-black-60pct: rgba(0, 0, 0, 0.6);
    --color-black-70pct: rgba(0, 0, 0, 0.7);
    --color-black-75pct: rgba(0, 0, 0, 0.75);
    --color-black-80pct: rgba(0, 0, 0, 0.8);
    --color-black-81pct: rgba(0, 0, 0, 0.81);
    --color-black-90pct: rgba(0, 0, 0, 0.9);
    --color-black-95pct: rgba(0, 0, 0, 0.95);
    --color-black-100pct: rgba(0, 0, 0, 1);

    --color-white-0pct: rgba(255, 255, 255, 0);
    --color-white-5pct: rgba(255, 255, 255, 0.05);
    --color-white-10pct: rgba(255, 255, 255, 0.1);
    --color-white-20pct: rgba(255, 255, 255, 0.2);
    --color-white-30pct: rgba(255, 255, 255, 0.3);
    --color-white-40pct: rgba(255, 255, 255, 0.4);
    --color-white-50pct: rgba(255, 255, 255, 0.5);
    --color-white-60pct: rgba(255, 255, 255, 0.6);
    --color-white-80pct: rgba(255, 255, 255, 0.8);
    --color-white-90pct: rgba(255, 255, 255, 0.9);
    --color-white-100pct: rgba(255, 255, 255, 1);

    --color-standard-text: var(--color-black-80pct);
    --color-subtext: var(--color-black-40pct);
    --color-line-black: var(--color-black-15pct);
    --color-hover-black: var(--color-black-5pct);
    --color-hover-white: rgba(255, 255, 255, 0.15);
    --color-hover-descript-red: rgba(255, 54, 91, 0.1);
    --color-selection: rgba(0, 212, 255, 0.35);
    --color-editor-selection: #a1aeff;
    --gradient-upgrade: linear-gradient(157deg, rgb(222, 40, 152), rgb(145, 102, 255));
    --gradient-free: linear-gradient(157deg, #ff365b, #ffa424);
    --gradient-creator: linear-gradient(157deg, rgb(0, 212, 254), #0049e6);
    --gradient-pro: linear-gradient(157deg, rgb(222, 40, 152), rgb(145, 102, 255));
    --gradient-beta: linear-gradient(
        157deg,
        var(--color-black-70pct),
        var(--color-black-30pct)
    );
    --color-resizer-border-color: rgba(255, 255, 255, 0);
    --color-resizer-border-color-hover: var(--color-black-20pct);
    --box-shadow-color: var(--color-black-10pct);
    --button-bar-color: rgba(255, 255, 255, 0.4);
    --tooltip-shortcut-color: rgba(255, 255, 255, 0.7);
    --color-white-background: #fff;
    --color-off-white-background: #fafafa;
    --color-dark-grey-foreground: #38434d;
    --color-dark-grey-background: #292d33;
    --color-light-grey-background: #ededed;
    --color-lightish-grey-background: #e0e0e0;
    --color-grey-background: #ccc;
    --color-white-foreground: #fff;
    --color-black-background: #000;
    --color-black-foreground: #000;
    --button-bar-text-color: var(--color-white-foreground);
    --speaker-cursor-color: var(--color-black-40pct);
    --script-background: var(--color-white-background);
    --script-popover-background: #0d0d0d;
    --color-controls-background: #f5f5f5;
    --accent-color-dark: transparent;
    --tau-color: var(--color-white-background);
    --tau-gap-color: #dcdcdc;
    --tau-scratch-text-color: #e5efff;
    --correct-text-color-rgb: 6, 210, 143;
    --correct-text-color: rgb(var(--correct-text-color-rgb)); /* #06d28f */
    --button-focus-inset: inset 0px 0px 0 2px var(--color-descript-blue);
    --button-focus: 0px 0px 0 2px white, 0px 0px 0 4px var(--color-descript-blue);

    --color-recorder-grey: #212429;
    --placeholder-color: #f1f1f1;

    --headerbar-height: 52px;
    --header-divider-height: 24px;

    --card-boundary-decoration-height: 28px;

    --text-editor-grid-template: 15% 1fr;
    --text-editor-gutter-max-width: 124px;

    --left-right-drag-cursor: -webkit-image-set(
                url('./Resources/cursors/left-right-pointer.png') 1x,
                url('./Resources/cursors/left-right-pointer@2x.png') 2x
            )
            12 2,
        ew-resize;
    --move-x-cursor: -webkit-image-set(
                url('./Resources/cursors/move_x.png') 1x,
                url('./Resources/cursors/move_x@2x.png') 2x
            )
            14.5 14.5,
        pointer; /* Webkit */
    --move-xy-cursor: -webkit-image-set(
                url('./Resources/cursors/move_xy.png') 1x,
                url('./Resources/cursors/move_xy@2x.png') 2x
            )
            14.5 14.5,
        pointer; /* Webkit */

    --audio-pin-color: #0af;
    --video-pin-color: #de2898;
    --image-pin-color: #ff8d3c;
    --title-pin-color: #9166ff;
    --shape-pin-color: #ff8d3c;
    --waveform-pin-color: #33d900;
    --progress-pin-color: #00d1b2;

    --media-bg-dark: #0d0d0d;
}

.portal-layer {
    z-index: var(--portal-layer);
}

[data-redesign] {
    --color-descript-blue: var(--blue-500);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    --color-black-foreground: var(--grey-800);
    --color-line-black: var(--grey-800);
    --media-bg: var(--grey-0);

    --audio-pin-color: var(--green-500-a);
    --video-pin-color: var(--blue-500-a);
    --image-pin-color: var(--blue-500-a);
    --title-pin-color: var(--blue-500-a);
    --shape-pin-color: var(--blue-500-a);
    --waveform-pin-color: var(--blue-500-a);
    --progress-pin-color: var(--blue-500-a);
    --placeholder-pin-color: var(--blue-500-a);

    --tau-scratch-text-color: var(--cyan-500-a);
    --audio-pin-text-color: var(--green-800);
    --video-pin-text-color: var(--blue-800);
    --image-pin-text-color: var(--blue-800);
    --title-pin-text-color: var(--blue-800);
    --shape-pin-text-color: var(--blue-800);
    --waveform-pin-text-color: var(--blue-800);
    --progress-pin-text-color: var(--blue-800);
    --placeholder-pin-text-color: var(--blue-800);

    --selection-fill: var(--cyan-500-a);
    --unfocused-selection-fill: var(--grey-50);
    --selection-border: var(--cyan-500);
    --pin-and-layer-selection-background: var(--cyan-500);
    --pin-and-layer-selection-border: var(--cyan-800);

    --card-highlight-fill: var(--cyan-500-a);
    --card-highlight-border: var(--cyan-500);
    --script-highlight-fill: var(--purple-500-a);
    --script-highlight-border: var(--purple-500);
    --script-underline: var(--purple-200);
    --audio-pin-highlight-border: var(--green-500);
    --audio-pin-highlight-fill: var(--green-500-a);
    --audio-pin-underline: var(--green-200);
    --drag-attach-to-clip-target-fill: var(--red-500);

    --gutter-decorations-margin-top: 6px;

    --secondary-cursor-color: var(--grey-50);
}

/* Increase specificity so it takes precedence over hover style, etc */
.selection-fill.selection-fill {
    background-color: var(--selection-fill);
}
.unfocused-selection-fill {
    background-color: var(--unfocused-selection-fill);
}

svg,
image {
    box-sizing: content-box;
}

svg {
    display: inline-block;
}

[data-redesign] * {
    --tau-gap-color: var(--grey-50);
    --color-standard-text: var(--grey-800);
    --color-subtext: var(--grey-400);
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Roboto,
        'Helvetica', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
    user-select: none;
}

#debug-settings,
#root[data-redesign] {
    background-color: var(--grey-0);
}

body *::selection {
    background-color: var(--color-selection);
}

[data-redesign] *::selection {
    background-color: var(--cyan-500-a);
}

html,
body,
#root {
    width: 100vw;
    height: 100vh;
    max-height: stretch;
    --font-weight-normal: 400;
    --font-weight-semibold: 500;
    --font-weight-bold: 600;
    --font-size-tiny: 11px;
    --font-size-fine-print: 12px;
    --font-size-regular: 13px;
    --font-size-label: 15px;
    --font-size-header: 19px;
    --font-size-large-header: 26px;
    --font-size-extra-large-header: 32px;
    --line-height-regular: 18px;
    --opacity-icon: 0.4;
    --transition-duration-fast: 100ms;
    --transition-duration-normal: 250ms;
}

html.dark-ok,
.dark-ok #root {
    background-color: var(--grey-800);
}

[data-keyboard-focus] a:focus,
[data-keyboard-focus] button:focus {
    box-shadow: var(--button-focus);
}

/* set user-select to none on electron to make it feel more native */
.darwin :not(input):not(textarea),
.win32 :not(input):not(textarea),
.darwin :not(input):not(textarea)::after,
.win32 :not(input):not(textarea)::after,
.darwin :not(input):not(textarea)::before,
.win32 :not(input):not(textarea)::before {
    user-select: none;
}

.darwin img,
.win32 img,
.darwin a,
.win32 a {
    -webkit-user-drag: none;
}

.Resizer {
    opacity: 0.2;
    z-index: 1;

    background-clip: padding-box;
    border: 5px solid var(--color-resizer-border-color);
}
[data-redesign] .Resizer:hover {
    border-color: var(--border-color);
}
.Resizer:hover {
    border-color: var(--color-resizer-border-color-hover);
}
.Resizer.disabled {
    display: none;
}
.Resizer.vertical {
    width: 10px;
    border-width: 0 5px;
    cursor: col-resize;
    margin: 10px -5px 0;
    border-radius: 5px;
    z-index: 2;
}
.Resizer.horizontal {
    height: 11px;
    border-width: 5px 0;
    cursor: row-resize;
    margin: -5px 0;
}
.SplitPane.horizontal > .Pane {
    height: 0;
}

.s-alert-info {
    background: var(--color-black-background);
    border-radius: 5px;
    padding: 20px 20px 10px 20px;
    box-shadow: 0 1px 4px 0 var(--box-shadow-color);
    font-size: 14px;
}

.s-alert-info .ButtonBar {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
}

.s-alert-info .ButtonBar .Button {
    border-radius: 3px;
    border: 1px solid var(--button-bar-text-color);
    padding: 2px 8px;
    text-transform: uppercase;
    cursor: pointer;
}

.s-alert-info .ButtonBar .Button:hover {
    background: var(--button-bar-color);
}

/* default tooltip content style */
.TooltipContent {
    font-size: 12px;
    font-weight: 500;
}

.TooltipContent__Shortcut {
    color: var(--tooltip-shortcut-color);
}

.ReactModalPortal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

[data-reach-skip-nav-link]:focus {
    outline-color: var(--color-descript-blue);
    color: var(--color-descript-blue);
}

.ElectronAppContainer {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

#ElectronAppPageContainer {
    position: relative;
    height: 100%;
    width: 100%;
}

[data-redesign] #ElectronAppPageContainer {
    overflow: hidden;
}

body.dragging {
    cursor: hand;
}

.scroll-overlay {
    overflow: auto !important;
    overflow-x: hidden;
    /* Note: overlay is deprecated and does not work in Safari https://caniuse.com/css-overflow-overlay */
    overflow-y: overlay !important;
}

.scroll-overlay::-webkit-scrollbar {
    width: 8px;
}

.scroll-overlay:not(:hover)::-webkit-scrollbar {
    display: none;
}

.scroll-overlay::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-clip: padding-box;
    border: 2px solid transparent;
    background-color: var(--border-color);
}

.scroll-overlay::-webkit-scrollbar-thumb:hover {
    border-width: 1px;
    background-color: var(--grey-400);
}

/** There is a bug in MacOS where cursors don't display in top 20px of window, so we just hide them */
.disable-cursors,
.disable-cursors * {
    cursor: default !important;
}

@font-face {
    font-family: 'DescriptWordlessMedia';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('Utilities/DescriptWordlessMedia.ttf') format('truetype');
}

/* Target Braze modals to ensure they are always interactable when rendered */
.ul-container {
    pointer-events: auto;
}

input::placeholder,
input::-webkit-input-placeholder,
textarea::placeholder,
textarea::-webkit-input-placeholder {
    color: var(--color-black-40pct);
}

[data-redesign] input::placeholder,
[data-redesign] input::-webkit-input-placeholder,
[data-redesign] textarea::placeholder,
[data-redesign] textarea::-webkit-input-placeholder {
    color: var(--grey-400);
}

#root:has(.Loader .Loading:not(.Hidden)) ~ #forethought-chat {
    visibility: hidden;
}
