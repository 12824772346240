/*
  Variables declared on :root aren't available in psuedo-elements like ::before and ::after
  Variables declared on html aren't available in svg elements

  So we use both to cover all our bases.
*/
:root,
html {
    --border-color: var(--grey-100-a);
}

div:focus,
span:focus,
button:focus,
button:focus:hover {
    outline: none;
}

:focus-visible,
button:focus-visible {
    outline: none;
}

input,
textarea {
    color: var(--color-standard-text);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Roboto,
        'Helvetica', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
}
[data-redesign] input,
[data-redesign] textarea {
    color: var(--grey-800);
}
input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
    -moz-appearance: number-input;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    appearance: none;
}

button,
[role='button'] {
    cursor: default;
}

:where([data-redesign]) {
    color: var(--grey-800);
}

[data-redesign] {
    --focus-ring-color: var(--cyan-600);
    --button-focus-inset: inset 0px 0px 0 2px var(--focus-ring-color);
    --button-focus-inverted: 0px 0px 0 2px var(--dark-grey-0),
        0px 0px 0 4px var(--dark-blue-500);
    --button-focus: 0px 0px 0 1px var(--grey-0), 0px 0px 0 3px var(--focus-ring-color);
}

[data-keyboard-focus] .button-focus-inset:focus {
    box-shadow: var(--button-focus-inset);
}

[data-keyboard-focus] .button-focus:focus {
    box-shadow: var(--button-focus);
}

[data-keyboard-focus] .button-focus-inverted:focus {
    box-shadow: var(--button-focus-inverted);
}

.border-grey-100-a.border-grey-100-a {
    border-color: var(--border-color);
}

.border {
    background-clip: padding-box;
}

.icon-token {
    background-color: var(--icon-color);
    mask: var(--icon-mask);
    -webkit-mask: var(--icon-mask);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-position: center;
    mask-size: var(--icon-size);
    -webkit-mask-size: var(--icon-size);
    height: var(--icon-size);
    width: var(--icon-size);
}

.cursor-token {
    background: var(--icon-mask);
    background-repeat: no-repeat;
    background-position: center;
    background-size: var(--icon-size);
    height: var(--icon-size);
    width: var(--icon-size);
}

.dark-ok[data-redesign],
[data-redesign] .dark-ok,
.dark-ok [data-redesign] {
    --grey-0: var(--dark-grey-0-base);
    --grey-5: var(--dark-grey-5-base);
    --grey-50: var(--dark-grey-50-base);
    --grey-200: var(--dark-grey-200-base);
    --grey-400: var(--dark-grey-400-base);
    --grey-800: var(--dark-grey-800-base);
    --grey-800: var(--dark-grey-800-base);
    --static-black: var(--dark-static-black-base);
    --border-color: var(--dark-grey-100-a-base);

    --grey-30-a: var(--dark-grey-30-a-base);
    --grey-50-a: var(--dark-grey-50-a-base);
    --grey-100-a: var(--dark-grey-100-a-base);
    --grey-400-a: var(--dark-grey-400-a-base);

    --cyan-200: var(--dark-cyan-200-base);
    --cyan-500: var(--dark-cyan-500-base);
    --cyan-600: var(--dark-cyan-600-base);
    --cyan-800: var(--dark-cyan-800-base);
    --cyan-500-a: var(--dark-cyan-500-a-base);

    --blue-200: var(--dark-blue-200-base);
    --blue-500: var(--dark-blue-500-base);
    --blue-800: var(--dark-blue-800-base);
    --blue-500-a: var(--dark-blue-500-a-base);

    --purple-200: var(--dark-purple-200-base);
    --purple-500: var(--dark-purple-500-base);
    --purple-800: var(--dark-purple-800-base);
    --purple-500-a: var(--dark-purple-500-a-base);

    --pink-200: var(--dark-pink-200-base);
    --pink-500: var(--dark-pink-500-base);
    --pink-800: var(--dark-pink-800-base);
    --pink-500-a: var(--dark-pink-500-a-base);

    --red-200: var(--dark-red-200-base);
    --red-500: var(--dark-red-500-base);
    --red-800: var(--dark-red-800-base);
    --red-500-a: var(--dark-red-500-a-base);

    --orange-200: var(--dark-orange-200-base);
    --orange-500: var(--dark-orange-500-base);
    --orange-800: var(--dark-orange-800-base);
    --orange-500-a: var(--dark-orange-500-a-base);

    --amber-200: var(--dark-amber-200-base);
    --amber-500: var(--dark-amber-500-base);
    --amber-800: var(--dark-amber-800-base);
    --amber-500-a: var(--dark-amber-500-a-base);

    --yellow-200: var(--dark-yellow-200-base);
    --yellow-500: var(--dark-yellow-500-base);
    --yellow-800: var(--dark-yellow-800-base);
    --yellow-500-a: var(--dark-yellow-500-a-base);

    --green-200: var(--dark-green-200-base);
    --green-500: var(--dark-green-500-base);
    --green-800: var(--dark-green-800-base);
    --green-500-a: var(--dark-green-500-a-base);

    --brown-200: var(--dark-brown-200-base);
    --brown-800: var(--dark-brown-800-base);

    --dark-grey-0: var(--grey-0-base);
    --dark-grey-5: var(--grey-5-base);
    --dark-grey-50: var(--grey-50-base);
    --dark-grey-200: var(--grey-200-base);
    --dark-grey-400: var(--grey-400-base);
    --dark-grey-800: var(--grey-800-base);
    --dark-grey-800: var(--grey-800-base);
    --dark-static-black: var(--static-black-base);
    --dark-border-color: var(--grey-100-a-base);

    --dark-grey-50-a: var(--grey-50-a-base);
    --dark-grey-30-a: var(--grey-30-a-base);
    --dark-grey-100-a: var(--grey-100-a-base);
    --dark-grey-400-a: var(--grey-400-a-base);

    --dark-cyan-200: var(--cyan-200-base);
    --dark-cyan-500: var(--cyan-500-base);
    --dark-cyan-600: var(--cyan-600-base);
    --dark-cyan-800: var(--cyan-800-base);
    --dark-cyan-500-a: var(--cyan-500-a-base);

    --dark-blue-200: var(--blue-200-base);
    --dark-blue-500: var(--blue-500-base);
    --dark-blue-800: var(--blue-800-base);
    --dark-blue-500-a: var(--blue-500-a-base);

    --dark-purple-200: var(--purple-200-base);
    --dark-purple-500: var(--purple-500-base);
    --dark-purple-800: var(--purple-800-base);
    --dark-purple-500-a: var(--purple-500-a-base);

    --dark-pink-200: var(--pink-200-base);
    --dark-pink-500: var(--pink-500-base);
    --dark-pink-800: var(--pink-800-base);
    --dark-pink-500-a: var(--pink-500-a-base);

    --dark-red-200: var(--red-200-base);
    --dark-red-500: var(--red-500-base);
    --dark-red-800: var(--red-800-base);
    --dark-red-500-a: var(--red-500-a-base);

    --dark-orange-200: var(--orange-800-base);
    --dark-orange-500: var(--orange-500-base);
    --dark-orange-800: var(--orange-800-base);
    --dark-orange-500-a: var(--orange-500-a-base);

    --dark-amber-200: var(--amber-200-base);
    --dark-amber-500: var(--amber-500-base);
    --dark-amber-800: var(--amber-800-base);
    --dark-amber-500-a: var(--amber-500-a-base);

    --dark-yellow-200: var(--yellow-200-base);
    --dark-yellow-500: var(--yellow-500-base);
    --dark-yellow-800: var(--yellow-800-base);
    --dark-yellow-500-a: var(--yellow-500-a-base);

    --dark-green-200: var(--green-200-base);
    --dark-green-500: var(--green-500-base);
    --dark-green-800: var(--green-800-base);
    --dark-green-500-a: var(--green-500-a-base);

    --dark-brown-200: var(--brown-200-base);
    --dark-brown-800: var(--brown-800-base);

    --chrome-0-elevated: var(--dark-chrome-0-elevated-base);
    --chrome-0-recessed: var(--dark-chrome-0-recessed-base);
    --dark-chrome-0-elevated: var(--chrome-0-elevated-base);
    --dark-chrome-0-recessed: var(--chrome-0-recessed-base);

    --upgrade-primary: var(--dark-upgrade-primary-base);
    --dark-upgrade-primary: var(--upgrade-primary-base);
    --upgrade-background: var(--dark-upgrade-background-base);
    --dark-upgrade-background: var(--upgrade-background-base);
    --upgrade-button-background: var(--dark-upgrade-button-background-base);
    --dark-upgrade-button-background: var(--upgrade-button-background-base);

    --state-inactive: var(--dark-state-inactive-base);
    --dark-state-inactive: var(--state-inactive-base);
}

.dark-ok[data-redesign] {
    background: var(--chrome-0-elevated);
}

.content-fade-above,
.content-fade-below,
.content-fade-end,
.content-fade-start {
    --content-fade-angle: 180deg;
    --content-fade-from: rgba(250, 248, 247, 0);
    --content-fade-to: var(--grey-0);
}

.content-fade-above::after,
.content-fade-below::after,
.content-fade-end::after,
.content-fade-start::before {
    content: '';
    height: 6px;
    left: 0;
    right: 0;
    position: absolute;
    background: linear-gradient(
        var(--content-fade-angle),
        var(--content-fade-from) 0%,
        var(--content-fade-to) 100%
    );
}

.content-fade-start::before {
    --content-fade-angle: 0deg;
    z-index: 1;
    top: 0;
}

.content-fade-below::after,
.content-fade-end::after {
    bottom: 0;
}

.content-fade-below::after {
    transform: translateY(100%);
    z-index: 1;
    --content-fade-angle: 0deg;
}

.content-fade-above::after {
    transform: translateY(-100%);
    z-index: 1;
}

.dark-ok .content-fade-below,
.dark-ok .content-fade-start,
.dark-ok .content-fade-start {
    --content-fade-from: rgba(17, 17, 17, 0);
    --content-fade-to: var(--dark-bg-color-solid, var(--grey-0));
}

.content-fade-to-sbrm-bg.content-fade-to-sbrm-bg.content-fade-to-sbrm-bg {
    --content-fade-to: var(--chrome-0-recessed);
}
