:root,
html {
    --duration-fast: 350ms;
    --duration-faster: 150ms;
    --ease-linear: cubic-bezier(0, 0, 1, 1);
    --ease-casual: cubic-bezier(0.25, 1, 0.5, 1);
    --ease-productive: cubic-bezier(0.22, 1, 0.36, 1);
    --ease-expressive: cubic-bezier(0.87, 0, 0.13, 1);
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill,
        stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: var(--ease-productive);
    transition-duration: var(--duration-fast);
}

.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill,
        stroke;
    transition-timing-function: var(--ease-productive);
    transition-duration: var(--duration-fast);
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: var(--ease-productive);
    transition-duration: var(--duration-fast);
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: var(--ease-productive);
    transition-duration: var(--duration-fast);
}

.duration-fast {
    transition-duration: var(--duration-fast);
}
.duration-faster {
    transition-duration: var(--duration-faster);
}
.ease-linear {
    transition-timing-function: var(--ease-linear);
}
.ease-casual {
    transition-timing-function: var(--ease-casual);
}
.ease-productive {
    transition-timing-function: var(--ease-productive);
}
.ease-expressive {
    transition-timing-function: var(--ease-expressive);
}
