:root,
html {
    --portal-layer: 10000;

    /* Timeline */
    --z-index-timeline-tau: 3;
    --z-index-timeline-playhead: 11;
    --z-index-above-pin-area: 1;
    --z-index-timeline-overlay: 1;
    --z-index-above-timeline-overlay: 2;
    --z-index-sidebar-flyout: calc(var(--z-index-timeline-playhead) + 1);
    --z-index-drag-cursor-preview: 20;

    /* Script */
    --z-index-script-timeline-resizer: 2;
    --z-index-script-dropzone: 2;
    --z-index-scrollbar-time-indicator: 100;
    --z-index-script-toolbar: 98;
    --z-index-write-mode-border: 99;
    --z-index-ai-actions-popover: 200;
    --z-index-speaker-submenu: 10001;

    /* App Chrome */
    --z-index-project-header: 200;
    --z-index-grid-resizer: 198;
    --z-index-extended-panel-dragged: 199;
    --z-index-selection-toolbar: 200;

    --z-index-drag-indicator: calc(var(--z-index-grid-toast) - 3);
    --z-index-extended-panel: calc(var(--z-index-grid-toast) - 1);
    --z-index-extended-panel-content: 10;
    --z-index-storyboard-remastered-sidebar-popup: calc(
        var(--z-index-extended-panel-content) - 1
    );
    --z-index-popover: calc(var(--z-index-grid-toast) - 1);
    --z-index-dialog: calc(var(--z-index-grid-toast) - 1);

    /* Indicators - Must be at top */
    --z-index-grid-toast: 1000;
    --z-index-tooltip: calc(var(--portal-layer) + 1);
}

.z-popover {
    z-index: var(--z-index-popover);
}

.z-grid-toast {
    z-index: var(--z-index-grid-toast);
}

[data-redesign] .z-brain-buddy-modal-portal {
    /*
     * Makes the Brain Buddy portal always be behind the recorder modals, 
     * over the timeline player controls, and over the panel resize splitter handles 
     */
    z-index: 199;
}
