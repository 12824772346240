:root,
html {
    --pulse-count: 4;
    --glow-radius: 10px;
}

.pulse-border {
    animation: border-animation calc(4 * var(--pulse-count) * var(--duration-fast))
        var(--ease-linear);
    box-shadow: inset 0 0 0 2px transparent;
    border-radius: 8px;
    overflow: visible;
    position: relative;
}

.pulse-glow-element::after,
.pulse-glow:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: var(--glow-radius);
    height: 100%;

    animation: glow calc(2 * var(--duration-fast)) calc(2 * var(--pulse-count))
        var(--ease-productive) alternate;
    box-shadow: none;
    border-radius: 8px;
    overflow: visible;
    pointer-events: none;
}

@keyframes border-animation {
    0% {
        box-shadow: inset 0 0 0 2px transparent;
    }

    15% {
        box-shadow: inset 0 0 0 2px var(--cyan-500);
    }

    85% {
        box-shadow: inset 0 0 0 2px var(--cyan-500);
    }

    100% {
        box-shadow: inset 0 0 0 2px transparent;
    }
}

@keyframes glow {
    0% {
        box-shadow: none;
    }

    65% {
        box-shadow: none;
    }

    100% {
        box-shadow: 0 0 0 var(--glow-radius) var(--cyan-500-a);
    }
}

.loading-block {
    background: linear-gradient(to right, var(--grey-50-a), var(--grey-0), var(--grey-50-a));
    background-size: 200% 100%;
    animation: gradient 1.5s linear infinite;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: -100% 50%;
    }

    100% {
        background-position: -200% 50%;
    }
}

@media (prefers-reduced-motion) {
    .loading-block {
        animation: none;
        background: var(--grey-50-a);
    }
}
